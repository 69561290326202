import {NewsCardProps} from "../components/NewsCard/NewsCard";
import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {NewsRequest} from "../types/NewsModels";
import {EntryCategory} from "../screens/Training/TrainingCategoriesModal/TrainingCategoriesModal";
import {ErrorStatus} from "../types/AuthState";

export const TrainingService = {
    getTrainingCourses: async (): Promise<NewsCardProps[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            const data = await response.json();
            data.sort((a: NewsCardProps, b: NewsCardProps) => new Date(b.date).getTime() - new Date(a.date).getTime());
            return data;
        } catch {
            throw new Error('unauthorized');
        }
    },
    getTrainingCourse: async (id: string): Promise<NewsCardProps> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/view/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getPendingTrainingCourses: async (): Promise<NewsCardProps[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/pending`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            const data = await response.json();
            data.sort((a: NewsCardProps, b: NewsCardProps) => new Date(b.date).getTime() - new Date(a.date).getTime());
            return data;
        } catch {
            throw new Error('unauthorized');
        }
    },
    addTraining: async (news: NewsRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(news)
            });

            if (response.status === 401) {
                Logout();
                return;
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    editTraining: async (news: NewsCardProps): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(news)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    changeTrainingStatus: async (id: string, status: boolean): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/${id}/status/${status}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 200)
                return Promise.resolve();

            if (response.status === 404)
                return Promise.reject(new Error('notFound'));

            return Promise.reject(new Error('unauthorized'));
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    deleteTraining: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 200)
                return Promise.resolve();

            if (response.status === 404)
                return Promise.reject(new Error('notFound'));

            return Promise.reject(new Error('unauthorized'));
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getCategories: async (): Promise<EntryCategory[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/categories`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    addCategory: async (category: EntryCategory): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/categories`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    updateCategory: async (category: EntryCategory): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/categories`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    deleteCategory: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/categories/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 422) {
                return Promise.reject(new Error('categoryDoesNotExists'));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    updateTrainingCategory: async (trainingId: string, categoryId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Training/${trainingId}/category/${categoryId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    }
}
