import React, {useEffect, useState} from 'react';
import './NewsList.css';
import {Menu, MenuProps, Pagination} from "antd";
import NewsCard, {NewsCardProps} from "../../../components/NewsCard/NewsCard";
import {NewsService} from "../../../services/NewsService";
import {useAuth} from "../../../extensions/Auth";
import BlankNewsCard from "../../../components/BlankNewsCard/BlankNewsCards";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {LoginService} from "../../../services/LoginService";
import {UserApp} from "../../../types/AuthState";
import {SystemService} from "../../../services/SystemService";
import {ItemType} from "antd/es/menu/hooks/useItems";
import getItem from "../../../extensions/Menu";

export enum TargetAppSpace {
    Menu = 1,
    Index = 2,
    All = 9999
}

type MenuItem = Required<MenuProps>['items'][number];

function NewsList() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [newsData, setNewsData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState([] as MenuItem[]);

    const filter = (p = 1, input = data) => setNewsData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    useEffect(() => {
        setLoading(true);
        NewsService.getNews().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });

        if (auth.user?.isAuthenticated) {
            LoginService.getUserApps().then(async (userApps: UserApp[]) => {
                const defaultApps: UserApp[] = await SystemService.getDefaultUserApps();
                const apps: ItemType[] = [];

                const allApps = [...userApps, ...defaultApps];
                const seenCategories: string[] = [];

                allApps
                    .filter(x => x.isCategory)
                    .forEach((sourceCat, index) => {
                        if (!seenCategories.includes(sourceCat.displayName))
                            seenCategories.push(sourceCat.displayName);
                        else
                            return;

                        const items = allApps.filter(y => y.category === sourceCat.displayName && (y.targetAppSpace === TargetAppSpace.Index || y.targetAppSpace === TargetAppSpace.All));

                        const children = items.map((app, appIndex) =>
                            getItem(app.displayName, `grp-${index}-${appIndex}`, null, undefined, undefined,
                                () => auth.navigate(app.url!, app.url?.startsWith('http'))));
                        const cat = getItem(sourceCat.displayName, `grp-${index}`, null, children,
                            'group');

                        if (children.length > 0)
                            apps.push(cat);
                    });

                const otherChildren = allApps
                    .filter(x => !x.isCategory && !x.category && (x.targetAppSpace === TargetAppSpace.Index || x.targetAppSpace === TargetAppSpace.All))
                    .map((app, appIndex) =>
                        getItem(app.displayName, `grp-others-${appIndex}`, null, undefined, undefined,
                            () => auth.navigate(app.url!, app.url?.startsWith('http'))))

                if (otherChildren.length > 0)
                    apps.push(getItem("Inne", `grp-others`, null, otherChildren, 'group'));

                setLinks(apps);
            });
        }

        return () => {
        };
    }, [auth, page]);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['news']}>Aktualności</RetoricBreadcrumbs>
            <div className={'flex flex-row w-full gap-4'}>
                <div className={'hidden lg:block'}>
                    <Menu
                        style={{width: 300}}
                        className="side-menu"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={links}
                    />
                </div>
                <div className="flex flex-col gap-4 self-stretch flex-1">
                    <div className={'flex flex-row justify-center lg:justify-start'}>
                        <RetoricButton outline onClick={() => auth.navigate('/news/new')} className="min-w-[150px]">Dodaj
                            aktualność</RetoricButton>
                    </div>
                    <div className="grid grid-cols-1 gap-4 flex-1 self-stretch">
                        {!loading && newsData.map((news, index) => <NewsCard key={`news-${index}`} {...news} />)}
                        {loading && [null, null, null, null, null].map((item, index) => <BlankNewsCard
                            key={`placeholder-news-${index}`}/>)}
                    </div>
                    <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination} className="mx-auto"
                                showSizeChanger={false}/>
                </div>
            </div>
        </div>
    );
}

export default NewsList;
