import React, {useEffect, useState} from 'react';
import './InfoEdit.css';
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes} from "../../../types/Scopes";
import {InfopageEditProps} from "./InfoEdit";
import {Collapse, Empty, Form, Input, notification, Select, Tooltip} from "antd";
import {
    ContactRecord,
    ContactRecordType,
    InfopagePhoneExtensions,
    PhoneExtension,
    PhoneExtensionEntry,
    PhoneExtensionEntryRequest,
    PhoneExtensionRequest
} from "../../../types/InfopageModels";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined,} from "@ant-design/icons";
import {InfopageService} from "../../../services/InfopageService";
import {ErrorMessages} from "../../../extensions/ErrorMessages";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";

const {Panel} = Collapse;

const GenerateEntryButtons = (entry: PhoneExtensionEntry,
                              setEntryEdit: (id: string) => void,
                              entryEdit: string,
                              setNewEntryTitle: (id: string) => void,
                              update: () => void) => {
    return (
        <>
            {entryEdit === entry.id ? <>
                <Tooltip title="Zapisz" className="ml-4 mt-1.5">
                    <SaveOutlined onClick={(event) => {
                        event.stopPropagation();
                        setEntryEdit('');
                        setNewEntryTitle('');
                        update();
                    }}/>
                </Tooltip>
                <Tooltip title="Anuluj" className="ml-4 mt-1.5">
                    <CloseOutlined onClick={(event) => {
                        event.stopPropagation();
                        setEntryEdit('')
                    }}/>
                </Tooltip>
            </> : <Tooltip title="Edytuj" className="ml-4 mt-1.5">
                <EditOutlined onClick={(event) => {
                    event.stopPropagation();
                    setEntryEdit(entry.id);
                    setNewEntryTitle(entry.title);
                }}/>
            </Tooltip>}
        </>
    )
}

const GenerateExtensionButtons = (extension: PhoneExtension,
                                  setExtensionEdit: (id: string) => void,
                                  extensionEdit: string,
                                  setNewExtensionTitle: (id: string) => void,
                                  setContactRecords: (contacts: ContactRecord[]) => void,
                                  setContactRecordValues: (contacts: ContactRecord[]) => void) => {
    return (
        <>
            {extensionEdit === extension.id ?
                <Tooltip title="Anuluj" className="ml-4 mt-1.5">
                    <CloseOutlined onClick={(event) => {
                        event.stopPropagation();
                        setExtensionEdit('')
                        setNewExtensionTitle('')
                        setContactRecords([] as ContactRecord[])
                        setContactRecordValues([] as ContactRecord[])
                    }}/>
                </Tooltip>
                : <Tooltip title="Edytuj" className="ml-4 mt-1.5">
                    <EditOutlined onClick={(event) => {
                        event.stopPropagation();
                        setExtensionEdit(extension.id);
                        setNewExtensionTitle(extension.title);
                        setContactRecords(extension.extensions)
                        setContactRecordValues(extension.extensions)
                    }}/>
                </Tooltip>}
        </>
    )
}

function PhoneNumbersInfopageEdit({infopage}: InfopageEditProps) {
    const auth = useAuth();
    const [page, setPage] = useState({} as InfopagePhoneExtensions);
    const [entryEdit, setEntryEdit] = useState('');
    const [newEntryTitle, setNewEntryTitle] = useState('');
    const [extensionEdit, setExtensionEdit] = useState('' as string);
    const [newExtensionTitle, setNewExtensionTitle] = useState('');
    const [contactRecords, setContactRecords] = useState([] as ContactRecord[]);
    const [contactRecordValues, setContactRecordValues] = useState([] as ContactRecord[]);
    const [entryDeleteModal, setEntryDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [extensionDeleteModal, setExtensionDeleteModal] = useState(false);
    const [modalExtensionPayload, setModalExtensionPayload] = useState({} as ConfirmationModalProps);
    const [addExtension, setAddExtension] = useState('');
    const [newExtensionName, setNewExtensionName] = useState('');
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const refreshData = () => {
        InfopageService.getInfopageById(infopage.id).then((x) => {
            setPage(x as InfopagePhoneExtensions)
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);

        refreshData();

        return () => {
        };
    }, [auth]);

    const updateEntry = () => {
        const payload: PhoneExtensionEntry = {
            extensions: [],
            id: entryEdit,
            title: newEntryTitle
        };

        InfopageService.updatePhoneExtensionEntry(infopage.id, entryEdit, payload)
            .then((x) => {
                api.info({
                    message: `Zaktualizowano wpis`,
                    description: 'Wpis został zaktualizowany!',
                    placement: 'top'
                });

                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na zaktualizowanie wpisu!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono wpisu!'
                }

                api.error({
                    message: `Nie udało się zaktualizować wpisu`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const updateExtension = (extension: PhoneExtension, entryId: string) => {
        extension.title = newExtensionTitle;
        extension.extensions = contactRecordValues;

        InfopageService.updatePhoneExtension(infopage.id, entryId, extension.id, extension)
            .then((x) => {
                api.info({
                    message: `Zaktualizowano wpis`,
                    description: 'Wpis został zaktualizowany!',
                    placement: 'top'
                });

                setExtensionEdit('');
                setNewExtensionTitle('');
                setContactRecordValues([] as ContactRecord[]);
                setContactRecords([] as ContactRecord[]);
                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na zaktualizowanie wpisu!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono wpisu!'
                }

                api.error({
                    message: `Nie udało się zaktualizować wpisu`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const addNewExtension = (entryId: string) => {
        const entry: PhoneExtensionRequest = {
            title: newExtensionName,
            extensions: []
        }

        if (!entry.title) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł nie może być pusty!',
                placement: 'top'
            });
            return;
        }

        InfopageService.addPhoneExtension(infopage.id, entryId, entry)
            .then((x) => {
                api.info({
                    message: `Dodano dział`,
                    description: 'Dział został utworzony!',
                    placement: 'top'
                });

                setExtensionEdit('');
                setNewExtensionTitle('');
                setContactRecordValues([] as ContactRecord[]);
                setContactRecords([] as ContactRecord[]);
                setAddExtension('');
                setNewExtensionName('')
                form.resetFields();
                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na utworzenie działu!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono strony!'
                }

                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Nazwa działu jest wymagana!'
                }

                api.error({
                    message: `Nie udało się utworzyć działu`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const deleteExtension = (entryId: string, extensionId: string) => {
        InfopageService.deletePhoneExtension(infopage.id, entryId, extensionId)
            .then((x) => {
                api.info({
                    message: `Usunięto grupę`,
                    description: 'Grupa kontaktów została usunięta!',
                    placement: 'top'
                });

                setExtensionEdit('');
                setNewExtensionTitle('');
                setContactRecordValues([] as ContactRecord[]);
                setContactRecords([] as ContactRecord[]);
                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na usunięcie grupy kontaktów!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono grupy!'
                }

                api.error({
                    message: `Nie udało się usunąć grupy`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const deleteEntry = (entryId: string) => {
        InfopageService.deletePhoneExtensionEntry(infopage.id, entryId)
            .then((x) => {
                api.info({
                    message: `Usunięto grupę`,
                    description: 'Grupa kontaktów została usunięta!',
                    placement: 'top'
                });

                setExtensionEdit('');
                setNewExtensionTitle('');
                setContactRecordValues([] as ContactRecord[]);
                setContactRecords([] as ContactRecord[]);
                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na usunięcie grupy kontaktów!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono grupy!'
                }

                api.error({
                    message: `Nie udało się usunąć grupy`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const addEntry = () => {
        const entry: PhoneExtensionEntryRequest = {
            title: form.getFieldsValue().title
        }

        if (!entry.title) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł nie może być pusty!',
                placement: 'top'
            });
            return;
        }

        InfopageService.addPhoneExtensionEntry(infopage.id, entry)
            .then((x) => {
                api.info({
                    message: `Dodano grupę`,
                    description: 'Grupa kontaktów została utworzona!',
                    placement: 'top'
                });

                setExtensionEdit('');
                setNewExtensionTitle('');
                setContactRecordValues([] as ContactRecord[]);
                setContactRecords([] as ContactRecord[]);
                form.resetFields();
                refreshData();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na utworzenie grupy kontaktów!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono strony!'
                }

                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Nazwa grupy jest wymagana!'
                }

                api.error({
                    message: `Nie udało się utworzyć grupy`,
                    description: error,
                    placement: 'top'
                });
            })
    }

    const removeRecord = (index: number) => {
        const records = [...contactRecords];
        records.splice(index, 1);
        setContactRecords(records);
        setContactRecordValues(records);
    }

    const updateRecordType = (index: number, type: ContactRecordType) => {
        const records = [...contactRecordValues];
        const record = records[index];
        record.type = type;
        records[index] = record;
        setContactRecordValues(records);
    }

    const updateRecordValue = (index: number, value: string) => {
        const records = [...contactRecordValues];
        const record = {...records[index]};
        record.value = value;
        records[index] = record;
        setContactRecordValues(records);
    }

    const addRecord = () => {
        const records = [...contactRecordValues];
        records.push({type: ContactRecordType.Phone, value: ''} as ContactRecord);
        setContactRecords(records);
        setContactRecordValues(records);
    }

    return (
        <div className="flex flex-col justify-stretch gap-4 min-h-full">
            <RetoricCard className="min-h-full min-w-full flex flex-col justify-stretch items-center flex-1">
                {contextHolder}
                {page.entries && page.entries.length > 0 && <Collapse className="self-stretch">
                    {page.entries?.map((entry) => (
                        <Panel header={entryEdit === entry.id ?
                            <Input value={newEntryTitle} className="rounded-none bg-offwhite" size={'small'}
                                   onChange={(e) => setNewEntryTitle(e.target.value)}/> : entry.title}
                               key={entry.id}
                               extra={GenerateEntryButtons(entry, setEntryEdit, entryEdit, setNewEntryTitle, updateEntry)}>

                            <div className="flex flex-row gap-4 self-stretch mb-4">
                                <RetoricButton size={'sm'} outline
                                               onClick={() => {
                                                   const payload = {
                                                       title: 'Usuń grupę kontaktów',
                                                       open: true,
                                                       setOpen: setEntryDeleteModal,
                                                       yesAction: () => {
                                                           setEntryDeleteModal(false);
                                                           deleteEntry(entry.id)
                                                       },
                                                       noAction: () => setEntryDeleteModal(false),
                                                   } as ConfirmationModalProps;


                                                   payload.infoboxTitle = "Wybrana grupa kontaktów posiada kontakty"
                                                   payload.children = <>
                                                       <p className="text-slate-600 mb-1">Jeżeli usuniesz grupę
                                                           kontaktów,
                                                           wszystkie przypisane kontakty zostaną wraz z nią
                                                           usunięte.</p>
                                                       <p className="text-slate-600">Usunięta grupa nie może zostać
                                                           przywrócona, czy jesteś pewien, że chcesz
                                                           usunąć "{entry.title}"?</p>
                                                   </>

                                                   setModelPayload(payload);
                                                   setEntryDeleteModal(true);
                                               }}>
                                    Usuń grupę "{entry.title}"
                                </RetoricButton>
                                <RetoricButton size={'sm'} outline icon={<PlusOutlined/>} onClick={() => {
                                    setAddExtension(entry.id)
                                    setNewExtensionName('')
                                }}>Dodaj dział</RetoricButton>
                            </div>
                            {(entry.extensions && entry.extensions.length > 0) && <Collapse className="self-stretch">
                                {entry.extensions?.map((extension) => (
                                    <Panel header={extension.title} key={extension.id}
                                           extra={GenerateExtensionButtons(extension, setExtensionEdit, extensionEdit, setNewExtensionTitle, setContactRecords, setContactRecordValues)}>
                                        <RetoricCard
                                            key={`${infopage.id}-${entry.id}-${extension.id}`}
                                            className="flex flex-col justify-stretch bg-gray-100/70">
                                            <RetoricButton size={'sm'} outline className="mb-4 self-start"
                                                           onClick={() => {
                                                               const payload = {
                                                                   title: 'Usuń dział',
                                                                   open: true,
                                                                   setOpen: setExtensionDeleteModal,
                                                                   yesAction: () => {
                                                                       setExtensionDeleteModal(false);
                                                                       deleteExtension(entry.id, extension.id)
                                                                   },
                                                                   noAction: () => setExtensionDeleteModal(false),
                                                               } as ConfirmationModalProps;


                                                               payload.infoboxTitle = "Wybrana dział posiada kontakty"
                                                               payload.children = <>
                                                                   <p className="text-slate-600 mb-1">Jeżeli usuniesz
                                                                       dział,
                                                                       wszystkie przypisane kontakty zostaną wraz z nim
                                                                       usunięte.</p>
                                                                   <p className="text-slate-600">Usunięty dział nie może
                                                                       zostać
                                                                       przywrócony, czy jesteś pewien, że chcesz
                                                                       usunąć "{extension.title}"?</p>
                                                               </>

                                                               setModalExtensionPayload(payload);
                                                               setExtensionDeleteModal(true);
                                                           }}>
                                                Usuń dział "{extension.title}"
                                            </RetoricButton>
                                            <h4 className="lg:text-lg text-md text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-2">
                                                {extension.id === extensionEdit ?
                                                    <Input value={newExtensionTitle}
                                                           className="rounded-none bg-offwhite"
                                                           size={'large'}
                                                           onChange={(e) => setNewExtensionTitle(e.target.value)}/> : extension.title}</h4>
                                            {extension.id === extensionEdit && contactRecords.map((record, index) => (
                                                <div
                                                    key={`${infopage.id}-${entry.id}-${extension.id}-${record.value}-${index}`}>
                                                    <>
                                                        <Form className="flex flex-row gap-4 min-w-full">
                                                            <Form.Item>
                                                                <Select value={contactRecordValues[index].type}
                                                                        className="bg-offwhite rounded-none min-w-[200px]"
                                                                        onChange={(e) => updateRecordType(index, e)}>
                                                                    <Select.Option
                                                                        value={ContactRecordType.Phone}>Telefon</Select.Option>
                                                                    <Select.Option
                                                                        value={ContactRecordType.Email}>Email</Select.Option>
                                                                    <Select.Option
                                                                        value={ContactRecordType.Web}>WWW</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item className="self-stretch flex-1">
                                                                <Input value={contactRecordValues[index].value}
                                                                       className="bg-offwhite rounded-none"
                                                                       onChange={(e) => updateRecordValue(index, e.target.value)}/>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Tooltip title="Usuń kontakt" className="ml-4 mt-1.5">
                                                                    <DeleteOutlined className="cursor-pointer"
                                                                                    onClick={() => removeRecord(index)}/>
                                                                </Tooltip>
                                                            </Form.Item>
                                                        </Form>
                                                    </>
                                                </div>))}
                                            {extension.id !== extensionEdit && extension.extensions.map((record, index) => (
                                                <div
                                                    key={`${infopage.id}-${entry.id}-${extension.id}-${record.value}-${index}`}>
                                                    {record.type === ContactRecordType.Phone &&
                                                        <div className="grid grid-cols-3 mb-1">
                                                            <span>Tel:</span>
                                                            <div className="col-span-2"><a
                                                                href={`tel:${record.value}`}>{record.value}</a>
                                                            </div>
                                                        </div>}
                                                    {record.type === ContactRecordType.Email &&
                                                        <div className="grid grid-cols-3 mb-1">
                                                            <span>Email:</span>
                                                            <div className="col-span-2"><a
                                                                href={`mailto:${record.value}`}>{record.value}</a>
                                                            </div>
                                                        </div>}
                                                    {record.type === ContactRecordType.Web &&
                                                        <div className="grid grid-cols-3 mb-1">
                                                            <div>WWW:</div>
                                                            <div className="col-span-2"><a href={`${record.value}`}
                                                                                           target={'_blank'}
                                                                                           rel={'noreferrer'}>{record.value}</a>
                                                            </div>
                                                        </div>}
                                                </div>))}
                                            {((!extension.extensions || extension.extensions.length === 0) && extension.id !== extensionEdit) &&
                                                <Empty
                                                    description='Brak kontaktów, użyj przycisku Edycji aby dodać nowe kontakty.'/>}
                                            <div className="flex flex-row gap-4">
                                                {extension.id === extensionEdit &&
                                                    <RetoricButton icon={<PlusOutlined/>} className="self-start"
                                                                   outline size='sm'
                                                                   onClick={() => addRecord()}>Nowy
                                                        kontakt</RetoricButton>}
                                                {extension.id === extensionEdit &&
                                                    <RetoricButton className="self-start" outline size='sm'
                                                                   onClick={() => setExtensionEdit('')}>Anuluj</RetoricButton>}
                                                {extension.id === extensionEdit &&
                                                    <RetoricButton className="self-start" size='sm'
                                                                   onClick={() => updateExtension(extension, entry.id)}>Zapisz</RetoricButton>}
                                            </div>
                                        </RetoricCard>
                                    </Panel>))}
                            </Collapse>}
                            {(!entry.extensions || entry.extensions.length === 0) && <Empty
                                description='Brak działów, dodaj pierwszy dział za pomocą przycisku "Dodaj dział".'/>}
                            {addExtension === entry.id && <div className="flex flex-row self-stretch mt-4">
                                <Input placeholder="Nazwa działu" value={newExtensionName}
                                       onChange={(e) => setNewExtensionName(e.target.value)}
                                       className="bg-offwhite rounded-none"/>
                                <RetoricButton size={'sm'} outline className={"ml-4"}
                                               onClick={() => setAddExtension('')}>Anuluj</RetoricButton>
                                <RetoricButton size={'sm'} className={"ml-4"}
                                               onClick={() => addNewExtension(entry.id)}>Dodaj</RetoricButton>
                            </div>}
                        </Panel>))}
                </Collapse>}
                {(!page.entries || page.entries.length === 0) && <Empty
                    description="Brak kontaktów, dodaj pierwszy kontakt korzystając z formularza na dole strony."/>}
            </RetoricCard>
            <RetoricCard className="bg-offwhite">
                <h4 className="lg:text-xl text-lg text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">
                    Dodaj nowy wpis do rejestru</h4>
                <Form
                    layout={'vertical'}
                    form={form}
                    className={"min-w-full"}
                >
                    <Form.Item label="Nazwa grupy" name="title"
                               rules={[{required: true, message: "Tytuł jest wymagany"}]}>
                        <Input size="large" placeholder="Nazwa grupy kontaktów"
                               className="bg-offwhite rounded-none"/>
                    </Form.Item>
                </Form>
                <RetoricButton type={'primary'} onClick={() => addEntry()} className="min-w-[200px]">Dodaj
                    grupę</RetoricButton>
            </RetoricCard>
            <ConfirmationModal {...modelPayload} open={entryDeleteModal} setOpen={setEntryDeleteModal}/>
            <ConfirmationModal {...modalExtensionPayload} open={extensionDeleteModal}
                               setOpen={setExtensionDeleteModal}/>
        </div>
    );
}

export default PhoneNumbersInfopageEdit;
