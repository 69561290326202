import React, {useEffect, useState} from 'react';
import './Profile.css';
import {Input, notification} from "antd";
import {User, UserRequest} from "../../types/AuthState";
import {LoginService} from "../../services/LoginService";
import Infobox from "../../designsystems/Infobox/Infobox";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../extensions/Locations";
import {UserService} from "../../services/UserService";
import ImageUploader, {FileObjectType} from "react-image-upload";
import {UploadOutlined} from "@ant-design/icons";
import 'react-image-upload/dist/index.css'
import {SystemService} from "../../services/SystemService";
import {GUID_EMPTY} from "../../types/Scopes";
import {ErrorMessages} from "../../extensions/ErrorMessages";

function Profile() {
    const [api, contextHolder] = notification.useNotification();

    const [form, setForm] = useState({scopes: []} as any);
    const [passwords, setPasswords] = useState({} as any);
    const [data, setData] = useState({} as User);
    const [uploadEnter, setUploadEnter] = useState(false);
    const [avatar, setAvatar] = useState(undefined as FileObjectType | undefined);
    const [activeProperties, setActiveProperties] = useState([] as number[]);
    const [minPasswordLength, setMinPasswordLength] = useState(10);

    const updateForm = (e: any, field: string) => {
        setForm({...form, [field]: e.target.value});
    }

    const publish = () => {
        const keys = ['firstName', 'lastName', 'holidayAllowance', 'organisation', 'position'];

        if (!form.firstName || !form.lastName || !form.holidayAllowance || !form.organisation || !form.position) {
            const errors = [];
            if (!form.firstName) errors.push('Imię');
            if (!form.lastName) errors.push('Nazwisko');
            if (!form.holidayAllowance) errors.push('Dni urlopowe');
            if (!form.organisation) errors.push('Dział');
            if (!form.position) errors.push('Stanowisko');

            api.error({
                message: `Uzupełnij wymagane pola`,
                description: `${errors.join(', ')} ${errors.length === 1 ? 'jest' : 'są'} wymagane!`,
                placement: 'top'
            });
            return;
        }

        for (const key of keys) {
            if (form[key].length > 70) {
                api.error({
                    message: `Zbyt długa wartość`,
                    description: `${key} może mieć maksymalnie 70 znaków!`,
                    placement: 'top'
                });
                return;
            }
        }

        const user: UserRequest = {
            name: `${form.firstName} ${form.lastName}`,
            firstName: form.firstName,
            lastName: form.lastName,
            holidayAllowance: form.holidayAllowance,
            organisation: form.organisation,
            position: form.position,
        }

        LoginService.editUser(user)
            .then((x) => editCallback(x, keys))
            .catch((e) => {
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas aktualizwania profilu!',
                    placement: 'top'
                });
            });
    }

    const updatePassword = () => {
        if (passwords.password !== passwords.passwordRepeat) {
            api.error({
                message: `Błąd`,
                description: 'Podane hasła nie są takie same!',
                placement: 'top'
            });
            return;
        }

        LoginService.updatePassword(passwords.password).then((x) => {
            api.info({
                message: `Zaktualizowano hasło`,
                description: `Twoje hasło zostało zmienione, możesz się teraz logować używając nowego hasła!`,
                placement: 'top'
            });
        }).catch((e) => {
            let message = 'Nowe hasło musi być inne od poprzedniego!';

            if (e.message === ErrorMessages.nameTooShort)
                message = `Hasło musi mieć co najmniej ${minPasswordLength} znaków!`;

            if (e.message === ErrorMessages.userNotFound)
                message = 'Wystąpił błąd wewnętrzny!';

            api.error({
                message: `Błąd`,
                description: message,
                placement: 'top'
            });
        });
    }

    const editCallback = (x: any, keys: string[]) => {
        if ("error" in x) {
            api.error({
                message: `Błąd`,
                description: x.error,
                placement: 'top'
            });
            return;
        }

        api.info({
            message: `Zaktualizowano profil`,
            description: `Dane Twojego profilu zostały zaktualizowane!`,
            placement: 'top'
        });
    }

    const verifyAvatar = (file: FileObjectType) => {
        if ((file.file.type !== 'image/png' && file.file.type !== 'image/jpeg' && file.file.type !== 'image/webp') || file.file.size > 1000000) {
            api.error({
                message: `Plik musi być obrazem`,
                description: `Plik musi być obrazem (.jpg, .png, .webp) o maksymalnym rozmiarze 1 Mb!`,
                placement: 'top'
            });

            return;
        }

        setAvatar(file);
    }

    const updateAvatar = () => {
        if (avatar === undefined) return;

        UserService.updateAvatar(avatar).then((x) => {
            api.info({
                message: `Zaktualizowano zdjęcie`,
                description: `Twoje zdjęcie został zaktualizowany!`,
                placement: 'top'
            });
        }).catch((e) => {
            api.info({
                message: `Wystąpił błąd`,
                description: `Wystąpił nieznany błąd, Twoje zdjęcie nie zostało zmienione`,
                placement: 'top'
            });
        });
    }

    useEffect(() => {
        SystemService.getAccountSettings().then((x) => {
            setActiveProperties(x.controllableProperties)
            setMinPasswordLength(x.minimumPasswordLength);
        });

        LoginService.getUser().then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            setData(x);
            setForm({
                firstName: x.firstName,
                lastName: x.lastName,
                holidayAllowance: x.holidayAllowance,
                organisation: x.organisation,
                position: x.position,
                email: x.email,
                manager: 'Brak manadżera',
                managerId: x.managerId
            });

            if (x.managerId)
                UserService.getRedactedUser(x.managerId).then((y: any) => {
                    if ("error" in x)
                        return;

                    setForm({
                        firstName: x.firstName,
                        lastName: x.lastName,
                        holidayAllowance: x.holidayAllowance,
                        organisation: x.organisation,
                        position: x.position,
                        email: x.email,
                        manager: x.managerId !== GUID_EMPTY ? `${y.name} (${y.email})` : 'Brak manadżera',
                        managerId: x.managerId
                    });
                });
        });

    }, []);

    return (
        <div className="content">
            <div className={'flex flex-col w-full gap-4'}>
                {contextHolder}
                <RetoricBreadcrumbs locations={Locations['settings']}>Ustawienia</RetoricBreadcrumbs>
                <Infobox title={'Ustawienia użytkownika'} rows={
                    [
                        'W tej skecji możesz zmienić ustawienia swojego konta.',
                        'Ustawienia, które mogą zostąć zmienione to dane personalne, hasło i preferencje.'
                    ]
                }/>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
                    <RetoricCard className="bg-offwhite lg:p-8 p-2 lg:col-span-2 flex flex-col justify-between gap-4">
                        <h3 className="text-lg text-slate-700 lg:col-span-2">Zmień dane</h3>
                        <div className="flex lg:flex-row flex-col gap-4">
                            <div className="flex">
                                <div className="flex flex-col gap-4">
                                    <div
                                        onMouseEnter={() => setUploadEnter(true)}
                                        onMouseLeave={() => setUploadEnter(false)}
                                    >
                                        <ImageUploader
                                            style={{
                                                height: 200,
                                                width: 200,
                                                backgroundImage: `${avatar === undefined ? `url(${UserService.getUserAvatar(data.avatar, data.email)})` : ''}`,
                                                backgroundSize: 'contain', // 'cover', 'contain', 'initial', 'inherit
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }} // style image to your preference
                                            deleteIcon={<div/>}
                                            uploadIcon={<div
                                                className={`h-[50px] w-[200px] flex flex-col justify-center items-center bg-brandColor-950/90 ${uploadEnter ? 'block' : 'hidden'} bottom-[-100px] left-[-100px] right-0 absolute`}>
                                                <UploadOutlined className="text-3xl text-offwhite"/></div>}
                                            onFileAdded={(img) => verifyAvatar(img)} // function that runs to confirm that your image actually exists
                                        />
                                    </div>
                                    <RetoricButton onClick={() => updateAvatar()} disabled={avatar === undefined}>Aktualizuj
                                        zdjęcie</RetoricButton>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <label>Imię <b>*</b></label>
                                    <Input size="large" placeholder="Jan" value={form['firstName']}
                                           onChange={(e: any) => updateForm(e, 'firstName')}
                                           className="bg-offwhite rounded-none"
                                           disabled={activeProperties.indexOf(1) === -1}
                                           required/>
                                </div>
                                <div>
                                    <label>Nazwisko <b>*</b></label>
                                    <Input size="large" placeholder="Nowak" value={form['lastName']}
                                           onChange={(e: any) => updateForm(e, 'lastName')}
                                           disabled={activeProperties.indexOf(2) === -1}
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div className="lg:col-span-2">
                                    <label>Stanowisko <b>*</b></label>
                                    <Input size="large" placeholder="Kierownik działu..." value={form['position']}
                                           onChange={(e: any) => updateForm(e, 'position')}
                                           disabled={activeProperties.indexOf(3) === -1}
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div>
                                    <label>Dział <b>*</b></label>
                                    <Input size="large" placeholder="Administracja" value={form['organisation']}
                                           onChange={(e: any) => updateForm(e, 'organisation')}
                                           disabled={activeProperties.indexOf(4) === -1}
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div>
                                    <label>Liczba dni urlopu (w roku) <b>*</b></label>
                                    <Input size="large" type={'number'} placeholder="25"
                                           value={form['holidayAllowance']}
                                           onChange={(e: any) => updateForm(e, 'holidayAllowance')}
                                           disabled={activeProperties.indexOf(5) === -1}
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div className="lg:col-span-2">
                                    <label>Adres email</label>
                                    <Input size="large" placeholder="jan@nowak.pl" value={form['email']}
                                           disabled
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div className="lg:col-span-2">
                                    <label>Bezpośredni przełożony</label>
                                    <Input size="large" placeholder="jan@nowak.pl" value={form['manager']}
                                           disabled
                                           className="bg-offwhite rounded-none"/>
                                </div>
                                <div>
                                    <RetoricButton onClick={() => publish()}
                                                   className="mt-4 self-start">Zapisz</RetoricButton>
                                </div>
                            </div>
                        </div>

                    </RetoricCard>
                    <RetoricCard className="bg-offwhite lg:p-8 p-2 flex flex-col justify-between">
                        <div className="grid grid-cols-1 gap-4">
                            <h3 className="text-lg text-slate-700">Zmień hasło</h3>
                            <div>
                                <label>Hasło</label>
                                <Input size="large" placeholder="Hasło..." value={passwords.password} type={'password'}
                                       onChange={(e: any) => setPasswords({...passwords, password: e.target.value})}
                                       className="bg-offwhite rounded-none"/>
                            </div>
                            <div>
                                <label>Powtórz hasło</label>
                                <Input size="large" placeholder="Powtórz hasło..." value={passwords.passwordRepeat}
                                       type={'password'}
                                       onChange={(e: any) => setPasswords({
                                           ...passwords,
                                           passwordRepeat: e.target.value
                                       })}
                                       className="bg-offwhite rounded-none"/>
                            </div>
                        </div>
                        <RetoricButton onClick={() => updatePassword()}
                                       className="mt-4 self-start">Zapisz</RetoricButton>
                    </RetoricCard>
                </div>
            </div>
        </div>
    );
}

export default Profile;
