import React, {useEffect, useState} from 'react';
import './SignalistConfiguration.css';
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes} from "../../../types/Scopes";
import {DatePicker, Form, Input, message, notification, Skeleton, Space, Table, Tooltip} from "antd";
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {Reports} from "../../../types/Signalist";
import SignalistService from "../../../services/SignalistService";
import {ColumnsType} from "antd/es/table";
import {CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, RedoOutlined, CopyOutlined} from "@ant-design/icons";
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";
import pl from "antd/es/date-picker/locale/pl_PL";
import dayjs from "dayjs";


function SignalistConfiguration() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as Reports[]);
    const [types, setTypes] = useState([] as Reports[]);
    const [categoryEdit, setCategoryEdit] = useState('');
    const [typeEdit, setTypeEdit] = useState('');
    const [newCategoryTitle, setNewCategoryTitle] = useState('');
    const [newTypeTitle, setNewTypeTitle] = useState('');
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [confirmModal, setConfirmModal] = useState(false);
    const [reportId, setReportId] = useState('');
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [categoryForm] = Form.useForm();
    const [typeForm] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const editCategory = (id: string) => {
        setCategoryEdit(id);
        setNewCategoryTitle(categories.find(x => x.id === id)?.title || '');
    }

    const editType = (id: string) => {
        setTypeEdit(id);
        setNewTypeTitle(types.find(x => x.id === id)?.title || '');
    }

    const saveEdit = () => {
        SignalistService.updateCategory(categoryEdit, newCategoryTitle)
            .then(() => {
                setCategoryEdit('');
                setNewCategoryTitle('');
                reloadCategories();
            })
            .catch((e) => {
                setCategoryEdit('');
                setNewCategoryTitle('');

                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się zapisać kategorii',
                    placement: 'top'
                });
            });
    }

    const saveTypeEdit = () => {
        SignalistService.updateType(typeEdit, newTypeTitle)
            .then(() => {
                setTypeEdit('');
                setNewTypeTitle('');
                reloadTypes();
            })
            .catch((e) => {
                setTypeEdit('');
                setNewTypeTitle('');

                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się zapisać statusu',
                    placement: 'top'
                });
            });
    }

    const deleteCategory = (id: string) => {
        SignalistService.deleteCategory(id)
            .then(() => {
                reloadCategories();
            })
            .catch((e) => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się usunąć kategorii',
                    placement: 'top'
                });
            });
    }

    const deleteType = (id: string) => {
        SignalistService.deleteType(id)
            .then(() => {
                reloadTypes();
            })
            .catch((e) => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się usunąć statusu',
                    placement: 'top'
                });
            });
    }

    const categoryColumns: ColumnsType<Reports> = [
        {
            title: 'Nazwa kategorii',
            dataIndex: 'title',
            key: 'title',
            width: '80%',
            render: (value, record) => <>
                {categoryEdit === record.id ?
                    <Input defaultValue={value} value={newCategoryTitle} className="rounded-none"
                           onChange={(e) => setNewCategoryTitle(e.target.value)}/>
                    :
                    <span>{value}</span>}
            </>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className="h-8">
                    {categoryEdit === record.id ? <>
                            <Tooltip title={'Zapisz'}>
                                <SaveOutlined className="cursor-pointer" onClick={() => saveEdit()}/>
                            </Tooltip>
                            <Tooltip title={'Anuluj'}>
                                <CloseOutlined className="cursor-pointer" onClick={() => setCategoryEdit('')}/>
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip title={'Edytuj'}>
                                <EditOutlined className="cursor-pointer" onClick={() => editCategory(record.id)}/>
                            </Tooltip>
                            <Tooltip title={'Usuń'}>
                                <DeleteOutlined onClick={() => {
                                    const payload = {
                                        title: 'Usuń status',
                                        open: true,
                                        setOpen: setConfirmModal,
                                        yesAction: () => {
                                            deleteCategory(record.id)
                                            setConfirmModal(false);
                                        },
                                        noAction: () => setConfirmModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrany kategoria zgłoszenia zostanie usunięta"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięta kategoria nie może zostać przywrócony,
                                            czy
                                            jesteś pewien, że chcesz usunąć {record.title}?</p>
                                    </>

                                    setModelPayload(payload);
                                    setConfirmModal(true);
                                }}/>
                            </Tooltip>
                        </>}
                </Space>
            ),
        }
    ];

    const typesColumns: ColumnsType<Reports> = [
        {
            title: 'Nazwa statusu',
            dataIndex: 'title',
            key: 'title',
            width: '80%',
            render: (value, record) => <>
                {typeEdit === record.id ?
                    <Input defaultValue={value} value={newTypeTitle} className="rounded-none"
                           onChange={(e) => setNewTypeTitle(e.target.value)}/>
                    :
                    <span>{value}</span>}
            </>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className="h-8">
                    {typeEdit === record.id ? <>
                            <Tooltip title={'Zapisz'}>
                                <SaveOutlined className="cursor-pointer" onClick={() => saveTypeEdit()}/>
                            </Tooltip>
                            <Tooltip title={'Anuluj'}>
                                <CloseOutlined className="cursor-pointer" onClick={() => setTypeEdit('')}/>
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip title={'Edytuj'}>
                                <EditOutlined className="cursor-pointer" onClick={() => editType(record.id)}/>
                            </Tooltip>
                            <Tooltip title={'Usuń'}>
                                <DeleteOutlined onClick={() => {
                                    const payload = {
                                        title: 'Usuń status',
                                        open: true,
                                        setOpen: setConfirmModal,
                                        yesAction: () => {
                                            deleteType(record.id)
                                            setConfirmModal(false);
                                        },
                                        noAction: () => setConfirmModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrany status osoby zgłaszającej zostanie usunięty"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięty status nie może zostać przywrócony, czy
                                            jesteś pewien, że chcesz usunąć {record.title}?</p>
                                    </>

                                    setModelPayload(payload);
                                    setConfirmModal(true);
                                }}/>
                            </Tooltip>
                        </>}
                </Space>
            ),
        }
    ];

    const addCategory = () => {
        categoryForm.validateFields()
            .then(x => {
                SignalistService.addCategory(x.title)
                    .then(x => {
                        reloadCategories();
                        categoryForm.resetFields();
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się dodać kategorii',
                            placement: 'top'
                        })
                    });
            });
    }

    const addType = () => {
        typeForm.validateFields()
            .then(x => {
                SignalistService.addType(x.title)
                    .then(x => {
                        reloadTypes();
                        typeForm.resetFields();
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się dodać typu',
                            placement: 'top'
                        })
                    });
            });
    }

    const updatePassword = () => {
        passwordForm.validateFields()
            .then(values => {
                if (values.password !== values.passwordConfirmation) {
                    api.error({
                        message: 'Wystąpił błąd',
                        description: 'Podane hasła nie są takie same!',
                        placement: 'top'
                    });

                    return;
                }

                SignalistService.updatePassword(values.password, values.passwordConfirmation)
                    .then(x => {
                        api.success({
                            message: 'Hasło zostało zmienione',
                            description: 'Przekaż nowe hasło pracownikom, by dalej mogli korzystać z systemu',
                            placement: 'top'
                        });

                        passwordForm.resetFields();
                    })
                    .catch(x => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się zmienić hasła',
                            placement: 'top'
                        });
                    })
            })
    }

    const reloadCategories = () => {
        SignalistService.getReportCategories()
            .then(x => {
                setCategories(x);
            });
    }

    const reloadTypes = () => {
        SignalistService.getTypes()
            .then(x => {
                setTypes(x);
            });
    }

    const newId = () => {
        SignalistService.getReportId().then((x) => {
            setReportId(x.replaceAll('"', ''));
        });
    }

    const copy = () => {
        navigator.clipboard.writeText(reportId);
        message.success('Skopiowano identyfikator!')
    }

    const openReport = () => {
        let from = undefined;
        let to = undefined;

        if (fromDate) {
            from = fromDate;
            from.setHours(0, 0, 0, 0);
        }

        if (toDate) {
            to = toDate;
            to.setHours(23, 59, 59, 999);
        }

        SignalistService.openFullReport(from, to)
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SIGNALIST);
        newId();

        SignalistService.getReportCategories()
            .then(x => {
                setCategories(x);
            })
            .catch(err => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać kategorii zgłoszeń',
                    placement: 'top'
                })
            })
            .then(x => {
                SignalistService.getTypes()
                    .then(x => {
                        setTypes(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać typów osób zgłaszających',
                            placement: 'top'
                        })
                    })
            })
            .finally(() => {
                setLoading(false);
            });

    }, []);

    return (
        <div className="content">
            {contextHolder}
            <Infobox
                title={'Ustawienia Retoric Signalist'}
                className="self-stretch"
                rows={[
                    'W tej skecji możesz zarządzać ustawieniami panelu anonimowych zgłoszeń.',
                    'Jako administrator, możesz zarządzać kategoriami zgłoszeń oraz statusami osób zgłaszających.',
                ]}
            />
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                <RetoricCard className="bg-offwhite self-stretch gap-4 flex flex-col">
                    <h2 className="text-lg tracking-wider font-ligh">Zmień hasło sygnalisty</h2>
                    <p className="text-slate-700 italic">To ustawienie pozwala na zmianę hasła do Retoric Signalist. Po zmianie hasła przekaż je wszystkim
                        pracownikom, aby dalej mogli korzystać z systemu.</p>
                    <Form className="gap-4"
                          onFinish={updatePassword}
                          form={passwordForm}
                          layout={'vertical'}>
                        <div className="flex flex-col">
                            <Form.Item label={"Nowe hasło"} required name="password"
                                       className="flex-1"
                                       rules={[{required: true, message: 'Hasło jest wymagane!'}]}>
                                <Input.Password placeholder={'Nowe hasło...'} className="rounded-none" size="large"/>
                            </Form.Item>
                            <Form.Item label={"Powtórz nowe hasło"} required name="passwordConfirmation"
                                       className="flex-1"
                                       rules={[{required: true, message: 'Hasło jest wymagane!'}]}>
                                <Input.Password placeholder={'Nowe hasło...'} className="rounded-none" size="large"/>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <RetoricButton htmlType={'submit'} size="md" type="primary">Zmień hasło</RetoricButton>
                        </Form.Item>
                    </Form>
                </RetoricCard>
                <RetoricCard className="bg-offwhite self-stretch gap-4 flex flex-col">
                    <h2 className="text-lg tracking-wider font-ligh">Wygeneruj raport</h2>
                    <p className="text-slate-700 italic">Raporty generowane są w formacie PDF. Jeżeli wybierzesz zakres dat to wygenerowany raport będzie
                        zawierał wszystkie zgłoszenia z tego okresu. Jeżeli nie wybierzesz zakresu dat to wygenerowany
                        raport będzie zawierał wszystkie zgłoszenia od początku. Wygenerowany raport zostanie otwarty w
                        nowym oknie</p>
                    <Form className="flex flex-col" layout={'vertical'}>
                        <Form.Item label={"Zakres daty od dnia"} className="mt-4">
                            <DatePicker locale={{...pl}} onChange={(d) => setFromDate(d?.toDate())}
                                        value={fromDate ? dayjs(fromDate) : undefined}
                                        className={"w-full bg-offwhite rounded-none"}/>
                        </Form.Item>
                        <Form.Item label={"Zakres daty do dnia"}>
                            <DatePicker locale={{...pl}} onChange={(d) => setToDate(d?.toDate())}
                                        value={toDate ? dayjs(toDate) : undefined}
                                        className={"w-full bg-offwhite rounded-none"}/>
                        </Form.Item>
                        <Form.Item>
                            <RetoricButton onClick={openReport} type="primary">Generuj raport</RetoricButton>
                        </Form.Item>
                    </Form>
                </RetoricCard>
                <RetoricCard className="bg-offwhite self-stretch gap-4 flex flex-col">
                    <h2 className="text-lg tracking-wider font-ligh">Wygeneruj nowy identyfikator</h2>
                    <p className="text-slate-700 italic">Ta funkcja pozwala na wygenerowanie nowego identyfikatora zgłoszenia. Jeżeli przyjmujesz
                        zgłoszenia telefonicznie, osobiście lub w inny sposób poza system, wygeneruj nowy identyfikator
                        i podaj go osobie zgłaszającej. A następnie wprowadź zgłoszenie do systemu korzystając z tego
                        identyfikatora.</p>
                    <div>
                        <p>Nowy identyfikator zgłoszenia
                            <Tooltip title="Wygeneruj nowy identyfikator">
                                <RedoOutlined className="ml-2 hover:text-brandColor-500" onClick={newId}/>
                            </Tooltip></p>
                        {reportId && <p className="italic">{reportId}
                            <Tooltip title="Skopiuj identyfikator">
                                <CopyOutlined className="ml-2 hover:text-brandColor-500" onClick={copy}/>
                            </Tooltip>
                        </p>}
                    </div>
                </RetoricCard>
            </div>
            {!loading && <div className={'self-stretch grid lg:grid-cols-2 grid-cols-1 gap-4'}>
                <RetoricCard className="bg-offwhite flex flex-col gap-4 justify-between">
                    <div className="flex flex-col gap-2 self-stretch flex-1">
                        <h2 className="text-lg tracking-wider font-ligh">Kategorie zgłoszeń</h2>
                        <Table columns={categoryColumns} dataSource={categories} rowKey={'id'}
                               pagination={{position: ['bottomCenter']}}
                               locale={{emptyText: <span>Brak kategorii...</span>}}/>
                    </div>
                    <Form
                        onFinish={addCategory}
                        form={categoryForm}
                        className="flex flex-row items-end gap-4"
                        layout={'vertical'}>
                        <Form.Item label={"Nazwa kategorii"} required name="title"
                                   className="flex-1"
                                   rules={[{required: true, message: 'Nazwa kategorii jest wymagana!'}]}>
                            <Input placeholder={'Nazwa kategorii...'} className="rounded-none" size="large"/>
                        </Form.Item>
                        <Form.Item>
                            <RetoricButton type={'primary'} htmlType={'submit'}>Dodaj kategorię</RetoricButton>
                        </Form.Item>
                    </Form>
                </RetoricCard>
                <RetoricCard className="bg-offwhite flex flex-col gap-4 justify-between">
                    <div className="flex flex-col gap-2 self-stretch flex-1">
                        <h2 className="text-lg tracking-wider font-ligh">Statusy osób zgłaszających</h2>
                        <Table columns={typesColumns} dataSource={types} rowKey={'id'}
                               pagination={{position: ['bottomCenter']}}
                               locale={{emptyText: <span>Brak statusów...</span>}}/>
                    </div>
                    <Form
                        onFinish={addType}
                        form={typeForm}
                        className="flex flex-row items-end gap-4"
                        layout={'vertical'}>
                        <Form.Item label={"Nazwa statusu"} required name="title"
                                   className="flex-1"
                                   rules={[{required: true, message: 'Nazwa statusu jest wymagana!'}]}>
                            <Input placeholder={'Nazwa statusu...'} className="rounded-none" size="large"/>
                        </Form.Item>
                        <Form.Item>
                            <RetoricButton type={'primary'} htmlType={'submit'}>Dodaj status</RetoricButton>
                        </Form.Item>
                    </Form>
                </RetoricCard>
            </div>}
            {loading && <Skeleton active/>}
            <ConfirmationModal {...modelPayload} open={confirmModal} setOpen={setConfirmModal}/>
        </div>
    );
}

export default SignalistConfiguration;
