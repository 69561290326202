import NavAdminMenu from "../../components/NavAdminMenu/NavAdminMenu";
import React from "react";
import {Route, Routes} from "react-router-dom";
import System from "../System/System";
import NewsRedaction from "../News/NewsRedaction/NewsRedaction";
import NewsAdd from "../News/NewsAdd/NewsAdd";
import NewsEdit from "../News/NewsEdit/NewsEdit";
import UsersList from "../Users/UsersList/UsersList";
import UserAdd from "../Users/UserAdd/UserAdd";
import UserEdit from "../Users/UserEdit/UserEdit";
import SoftwareRedaction from "../Software/SoftwareRedaction/SoftwareRedaction";
import SoftwareAdd from "../Software/SoftwareAdd/SoftwareAdd";
import SoftwareEdit from "../Software/SoftwareEdit/SoftwareEdit";
import TrainingRedaction from "../Training/TrainingRedaction/TrainingRedaction";
import TrainingEdit from "../Training/TrainingEdit/TrainingEdit";
import TrainingAdd from "../Training/TrainingAdd/TrainingAdd";
import InfopageList from "../Info/InfopageList/InfopageList";
import InfoAdd from "../Info/InfoAdd/InfoAdd";
import InfoEdit from "../Info/InfoEdit/InfoEdit";
import AiAssistantRedaction from "../AiAssistant/AiAssistantRedaction/AiAssistantRedaction";
import AiConfiguration from "../AiAssistant/AiConfiguration/AiConfiguration";
import SignalistList from "../Signalist/SignalistList/SignalistList";
import SignalistConfiguration from "../Signalist/SignalistConfiguration/SignalistConfiguration";
import SignalistReportView from "../Signalist/SignalistReportView/SignalistReportView";
import SignalistNewReport from "../Signalist/SignalistNewReport/SignalistNewReport";

const routes = [
    {
        path: '/',
        element: <System/>
    },
    {
        path: '/news',
        element: <NewsRedaction/>
    },
    {
        path: '/news/new',
        element: <NewsAdd/>
    },
    {
        path: "/news/edit/:id",
        element: <NewsEdit/>,
    },
    {
        path: '/users',
        element: <UsersList/>
    },
    {
        path: "/users/new",
        element: <UserAdd/>,
    },
    {
        path: "/users/edit/:userEmail",
        element: <UserEdit/>,
    },
    {
        path: '/software',
        element: <SoftwareRedaction/>
    },
    {
        path: "/software/new",
        element: <SoftwareAdd/>,
    },
    {
        path: "/software/edit/:id",
        element: <SoftwareEdit/>,
    },
    {
        path: '/training',
        element: <TrainingRedaction/>
    },
    {
        path: "/training/new",
        element: <TrainingAdd/>,
    },
    {
        path: "/training/edit/:id",
        element: <TrainingEdit/>,
    },
    {
        path: '/info',
        element: <InfopageList/>
    },
    {
        path: "/info/new",
        element: <InfoAdd/>,
    },
    {
        path: "/info/edit/:id",
        element: <InfoEdit/>,
    },
    {
        path: '/aiassistant',
        element: <AiAssistantRedaction/>
    },
    {
        path: "/aiassistant/config",
        element: <AiConfiguration/>,
    },
    {
        path: '/signalist',
        element: <SignalistList/>
    },
    {
        path: '/signalist/config',
        element: <SignalistConfiguration/>
    },
    {
        path: '/signalist/view/:id',
        element: <SignalistReportView/>
    },
    {
        path: '/signalist/new',
        element: <SignalistNewReport/>
    }
]
export default function AdminView() {
    return (
        <div className="content">
            <div className="flex lg:flex-row flex-col self-stretch gap-4">
                <NavAdminMenu/>
                <div className="flex-1">
                    <Routes>
                        {routes.map((route, i) => (
                            <Route key={i} path={route.path} element={route.element}/>
                        ))}
                    </Routes>
                </div>
            </div>
        </div>
    )
}
