import {
    InfopageBasic,
    InfopageChangelog,
    InfopagePhoneExtensions,
    InfopageTraining,
    InfopageType
} from "../../../types/InfopageModels";

const GeneratePastDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - Math.floor(Math.random() * 100));
    return date;
}

const GenerateFutureDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + Math.floor(Math.random() * 100));
    return date;
}

const GenerateDate = (number: number) => {
    const date = new Date();
    date.setDate(date.getDate() + number);
    return date;
}

export const PhoneNumbersMock: InfopagePhoneExtensions = {
    createdAt: new Date(),
    entries: [
        {
            id: "1",
            title: "Przykładowa kategoria",
            extensions: [
                {
                    id: "1",
                    title: "Przykładowy rekord",
                    extensions: [
                        {
                            type: 0,
                            value: "+48 32 345 6789"
                        },
                        {
                            type: 1,
                            value: "kontakt@retoric.pl"
                        },
                        {
                            type: 2,
                            value: "https://retoric.pl"
                        }
                    ],
                },
                {
                    id: "2",
                    title: "Inny rekord",
                    extensions: [
                        {
                            type: 0,
                            value: "+48 987 654 321"
                        },
                        {
                            type: 1,
                            value: "contact@retoric.pl"
                        },
                    ]
                }
            ]
        },
        {
            id: "2",
            title: "Inna kategoria",
            extensions: [
                {
                    id: "1",
                    title: "Przykładowy rekord",
                    extensions: [
                        {
                            type: 0,
                            value: "+44 1234 567 890"
                        },
                        {
                            type: 1,
                            value: "contact@retoric.pl"
                        },
                        {
                            type: 2,
                            value: "https://retoric.pl"
                        }
                    ],
                }
            ]
        }
    ],
    id: "1",
    isHidden: false,
    order: 0,
    title: "Zbiór kontaktów",
    type:
    InfopageType.PhoneExtensions,
    updatedAt: new Date()
}

export const BasicMock: InfopageBasic = {
    createdAt: new Date(),
    htmlContent: "<div><h1 style='font-size: 1rem; font-weight: 500'>Przykładowa treść</h1><p>Ta strona jest w pełni konfigurowalna za pomocą edytora WYSWIG.</p></div>",
    id: "1",
    isHidden: false,
    order: 0,
    title: "Strona tekstowa",
    type: InfopageType.Basic,
    updatedAt: new Date()
}

export const TrainingMock: InfopageTraining = {
    createdAt: new Date(),
    entries: [
        {
            id: "1",
            title: "Szkolenie Retoric",
            date: GenerateDate(10),
            description: "Szkolenie z obsługi intranetu Retoric",
            trainingProvider: "Retoric sp. z o.o.",
            location: "Siedziba firmy",
            infoUrl: "https://retoric.pl"
        },
        {
            id: "2",
            title: "Szkolenie AI",
            date: GenerateDate(5),
            description: "Szkolenie z wykorzystywania sztucznej inteligencji w biznesie",
            trainingProvider: "Retoric sp. z o.o.",
            location: "Biuro Retoric",
            infoUrl: "https://retoric.pl"
        },
        {
            id: "3",
            title: "Szkolenie Retoric (historyczne)",
            date: GeneratePastDate(),
            description: "Szkolenie z obsługi intranetu Retoric",
            trainingProvider: "Retoric sp. z o.o.",
            location: "Siedziba firmy",
            infoUrl: "https://retoric.pl"
        }
    ],
    id: "1",
    isHidden: false,
    order: 0,
    title: "Wykaz szkoleń",
    type:
    InfopageType.Basic,
    updatedAt: new Date()
}

export const ChangelogMock: InfopageChangelog = {
    createdAt: new Date(),
    entries: [
        {
            id: "3",
            title: "Poprawki regulaminu - urlopy",
            date: GenerateDate(-5),
            description: "Dodano informacje na temat urlopu w trakcie świąt\nDodano informacje o procesie urlopowym",
        },
        {
            id: "2",
            title: "Poprawki regulaminu",
            date: GenerateDate(-1),
            description: "Zmienione dane spółki\nDodano sekcje o prywatności\nDodano sekcje o bezpieczeństwie",
        },
        {
            id: "1",
            title: "Pierwsza wersja regulaminu",
            date: GenerateDate(-10),
            description: "Utworzono regulamin",
        },
    ],
    id: "1",
    isHidden: false,
    order: 0,
    title: "Rejestr zmian",
    type:
    InfopageType.Basic,
    updatedAt: new Date()
}
