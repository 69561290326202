import React, {useEffect, useRef, useState} from 'react';
import './AiAssistant.css';
import {Layout, List, Space} from "antd";
import Loader from "./components/Loader";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {useAuth} from "../../extensions/Auth";
import BotLogo from '../../assets/logotype-dark.svg'
import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../extensions/Locations";
import {BACKEND_BASE} from "../../services/LoginService";
import {AiChatMessage, AiChatMessageRequest, AiChatRedacted} from "../../types/AiChat";
import {AiChatService} from "../../services/AiChatService";
import TextArea from "antd/es/input/TextArea";

const {Footer, Sider, Content} = Layout;

function AiAssistant() {
    const auth = useAuth();
    const [chats, setChats] = useState([] as AiChatRedacted[])
    const [messages, setMessages] = useState([] as AiChatMessage[])


    const [userMessage, setUserMessage] = useState<string>('');
    const [activeChat, setActiveChat] = useState<string | null>(null);
    const [botTyping, setBotTyping] = useState<boolean>(false);
    const [showChats, setShowChats] = useState<boolean>(false);
    const [responseInProgress, setResponseInProgress] = useState<boolean>(false);

    const messagesEndRef = useRef(null as any)

    const loadChat = (id: string) => {
        setActiveChat(id);

        AiChatService.getChat(id).then((data) => {
            setMessages(data.messages)
        });
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollTo({top: messagesEndRef.current?.scrollHeight, behavior: "smooth"})
    }

    const sendMessage = async () => {
        if (userMessage.length === 0) {
            return;
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${auth.user?.token}`);

        const message: AiChatMessageRequest = {
            message: userMessage,
        }

        const currentMessages = [...messages, {content: userMessage, role: 'user'}];

        if (activeChat) {
            message.chatId = activeChat;
        } else {
            setActiveChat('new');
        }

        setMessages(currentMessages)

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(message)
        };

        setBotTyping(true);
        setResponseInProgress(true);

        const send = async () => {
            try {
                setUserMessage('');
                const response = await fetch(`${BACKEND_BASE}/Chatbot`, requestOptions);
                const newMessages = [...currentMessages, {content: '', role: 'assistant'}];

                if (!response.ok || !response.body) {
                    console.error('Nie można pobrać wiadomości');
                    return;
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                while (true) {
                    const {value, done} = await reader.read();

                    if (done) break;

                    const decodedChunk = decoder.decode(value, {stream: true})

                    if (decodedChunk.startsWith('---RETORIC_STREAM_START---')) {
                        const controlMsg: {
                            ChatId: string
                        } = JSON.parse(decodedChunk.replace('---RETORIC_STREAM_START---', ''));

                        AiChatService.getChats()
                            .then((data) => {
                                setChats(data);
                                loadChat(controlMsg.ChatId);
                                setResponseInProgress(true);
                            });

                        continue;
                    }

                    setBotTyping(false);

                    if (decodedChunk.startsWith('---RETORIC_STREAM_END---')) {
                        const controlMsg: {
                            ChatId: string
                        } = JSON.parse(decodedChunk.replace('---RETORIC_STREAM_END---', ''));

                        AiChatService.getChats()
                            .then((data) => {
                                setChats(data);
                                loadChat(controlMsg.ChatId);
                                setResponseInProgress(false);
                            });

                        break;
                    }

                    const msgs = [...newMessages];
                    msgs[msgs.length - 1].content = newMessages[newMessages.length - 1].content + decodedChunk;

                    setMessages([...msgs]);
                    scrollToBottom();
                }
            } catch (error) {
                console.error('Nie można pobrać wiadomości');
                setResponseInProgress(false);
            }
        };

        await send();
    }

    const newChat = () => {
        setActiveChat(null);
        setMessages([]);
    }

    useEffect(() => {
        AiChatService.getChats().then((data) => {
            setChats(data);
        });

        return () => {
        };
    }, []);

    return (
        <div className="content min-h-full">
            <RetoricBreadcrumbs locations={Locations['aiassistant']}>Asystent AI</RetoricBreadcrumbs>
            <Layout
                className={'flex lg:flex-row flex-col gap-4 w-full rounded-2xl lg:height-sider-restricted min-h-full'}>
                <Sider className="aiassistant__sider bg-none-important rounded-2xl hidden lg:block" width={300}>
                    <List
                        size="large"
                        bordered
                        locale={{emptyText: 'Rozpocznij swój pierwszy czat'}}
                        className={'sider'}
                        header={<List.Item
                            className={'first:rounded-t-2xl hover:cursor-pointer m-0 p-0-important hover:font-medium'}
                            onClick={newChat}>Nowy chat</List.Item>}
                        dataSource={chats}
                        renderItem={(item) => <List.Item
                            className={'hover:cursor-pointer aiassistant__sider-entry hover:font-medium'}
                            onClick={() => loadChat(item.id)}>{item.title}</List.Item>}
                    >
                    </List>
                </Sider>
                <Layout>
                    <Content
                        className="flex-1 self-stretch bg-offwhite-important rounded mb-4 height-restricted overflow-auto shadow">
                        <div className="min-h-full min-w-full bg-gray-100" ref={messagesEndRef}>
                            {activeChat && messages.map((message: AiChatMessage, index: number) => {
                                return (
                                    <div
                                        key={`message-${index}`}
                                        className={'lg:p-6 p-2' + (message.role === 'user' ? ' bg-gray-100 text-slate-950' : ' bg-offwhite text-slate-950')}>
                                        <div className="flex flex-row gap-4 container">
                                            {message.role === 'user' ?
                                                <img src={`${BACKEND_BASE}/User/avatar/${auth.user?.email}`}
                                                     alt={auth.user?.name}
                                                     className="h-12 w-12"/> :
                                                <img src={BotLogo} alt={"Asystent AI"} className="h-12 w-12"/>
                                            }
                                            <pre style={{whiteSpace: 'pre-wrap'}}>{message.content}</pre>
                                        </div>
                                    </div>
                                )
                            })}
                            {botTyping &&
                                <div className="bg-offwhite text-slate-950">
                                    <div className="flex flex-row gap-4 container">
                                        <img src={BotLogo} alt={"Asystent AI"} className="h-12 w-12"/>
                                        <Loader style={{height: '40px'}}/>
                                    </div>
                                </div>}
                            {!activeChat &&
                                <div className={'lg:p-6 p-2 text-center'}>Napisz wiadomość, aby rozpocząć
                                    czat z naszym asystentem AI.</div>}
                        </div>
                    </Content>
                    <Footer className="self-stretch p-0-important flex bg-offwhite-important rounded-2xl mb-4 lg:mb-0">
                        <Space.Compact style={{width: '100%'}} size={'large'}>
                            <TextArea placeholder="Napisz wiadomość..." value={userMessage}
                                      onKeyDown={((e) => {
                                          if (e.key === 'Enter' && !e.shiftKey) {
                                              sendMessage();
                                          }
                                      })}
                                      rows={1}
                                      disabled={responseInProgress}
                                      className="bg-offwhite-important rounded-none placeholder:text-gray-500"
                                      onChange={(data) => setUserMessage(data.target.value)}/>
                            <RetoricButton type="primary" onClick={sendMessage}>Wyślij</RetoricButton>
                        </Space.Compact>
                    </Footer>
                    <RetoricButton className="lg:hidden"
                                   onClick={() => setShowChats(!showChats)}>{showChats ? 'Ukryj' : 'Pokaż'} historyczne
                        czaty</RetoricButton>
                    {showChats && <List
                        size="large"
                        bordered
                        className={'lg:hidden max-h-[160px] overflow-auto'}
                        header={<List.Item
                            className={'first:rounded-t-2xl hover:font-medium hover:cursor-pointer m-0 p-0-important'}
                            onClick={newChat}>Nowy chat</List.Item>}
                        dataSource={chats}
                        renderItem={(item) => <List.Item className={'hover:font-medium hover:cursor-pointer'}
                                                         onClick={() => loadChat(item.id)}>{item.title}</List.Item>}
                    >
                    </List>}
                </Layout>
            </Layout>
        </div>
    );
}

export default AiAssistant;
