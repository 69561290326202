import React, {useEffect, useState} from 'react';
import './SoftwareList.css';
import {Empty, Input, Menu, MenuProps, Pagination} from "antd";
import SoftwareCard, {SoftwareCardProps} from "../../../components/SoftwareCard/SoftwareCard";
import {useAuth} from "../../../extensions/Auth";
import {SoftwareService} from "../../../services/SoftwareService";
import {AdminScopes} from "../../../types/Scopes";
import BlankNewsCard from "../../../components/BlankNewsCard/BlankNewsCards";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import getItem from "../../../extensions/Menu";
import {EntryCategory} from "../../Training/TrainingCategoriesModal/TrainingCategoriesModal";

type MenuItem = Required<MenuProps>['items'][number];

function SoftwareList() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as SoftwareCardProps[]);
    const [searchFilter, setSearchFilter] = useState('' as string);
    const [softwareData, setSoftwareData] = useState([] as SoftwareCardProps[]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [categoryMenuItems, setCategoryMenuItems] = useState([] as MenuItem[]);
    const [categories, setCategories] = useState([] as EntryCategory[]);
    const [category, setCategory] = useState('all' as string);

    const refreshCategories = (c = categories) => {
        const items = [] as MenuItem[];

        items.push(getItem(`Wszystkie programy`, `category-all`, null, undefined, undefined,
            () => null))

        items.push(...c.filter((x) => x.entries && x.entries.length > 0).map((category) => {
            return getItem(`${category.name}`, `category-${category.id}`, null, undefined, undefined,
                () => null)
        }))

        items.push(getItem(`Pozostałe`, `category-others`, null, undefined, undefined,
            () => null))

        const cat = getItem('Kategorie', `grp-0`, null, items, 'group');

        setCategoryMenuItems([cat]);
    }

    const filter = (p = 1, f = '', input = data, c = category, cats = categories) => {
        const search = input.filter((software) => software.title.toLowerCase().includes(f.toLowerCase()) || software.description.toLowerCase().includes(f.toLowerCase()));
        const categoryResult = search.filter((x) => {
            if (c === 'all')
                return true;
            else if (c === 'others')
                return x.categoryId === null;
            else
                return x.categoryId === c;
        });

        const pagination = categoryResult.filter((_, index) => index >= (p - 1) * 16 && index < p * 16);

        setSoftwareData(pagination);
        setTotal(categoryResult.length);
        refreshCategories(cats);
    }

    let searchTimeout = setTimeout(() => {
    }, 1000);

    const filterBySearch = (event: any) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setSearchFilter(event.target.value);
            filter(1, event.target.value, data);
        }, 200);
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SOFTWARE);

        setLoading(true);
        SoftwareService.getCategories().then((c) => {
            setCategories(c);

            SoftwareService.getSoftware().then((software) => {
                setData(software);
                filter(page, searchFilter, software, category, c);
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                auth.handleLogout();
            });
        });
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page, searchFilter, data);
    }

    const selectCategory = (selectedCategory: { key: string }) => {
        const key = selectedCategory.key.replaceAll('category-', '');

        setCategory(key);
        setPage(1);
        filter(1, searchFilter, data, key);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['software']}>Oprogramowanie</RetoricBreadcrumbs>
            <div className="flex flex-row justify-stretch gap-4 self-stretch">
                <div className={'hidden lg:block'}>
                    <Menu
                        style={{width: 300}}
                        className="side-menu"
                        defaultSelectedKeys={['category-all']}
                        onSelect={selectCategory}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={categoryMenuItems}
                    />
                </div>
                <div className="flex flex-col justify-stretch gap-4 self-stretch flex-1">
                    <div className="lg:self-start self-stretch">
                        <Input.Search placeholder={'Wpisz frazę do wyszukania'} allowClear size={'large'}
                                      onChange={filterBySearch} className="lg:min-w-[500px] search"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 self-stretch">
                        {!loading && softwareData.map((software, index) => <SoftwareCard
                            key={`software-${software.title}-${index}`} {...software}
                            onClick={software.url ? () => auth.navigate(software.url!, software.url!.startsWith('https')) : () => {
                            }}/>)}
                        {!loading && softwareData.length === 0 && <Empty className="lg:col-span-4"
                                                                         description={<span>Brak oprogramowania. Poproś administratora o dodanie oprogramowania!</span>}/>}
                        {loading && [null, null, null, null, null, null, null, null].map((item, index) => <BlankNewsCard
                            key={`placeholder-software-${index}`}/>)}
                    </div>
                    <Pagination defaultCurrent={1} total={total} onChange={updatePagination} showSizeChanger={false}
                                pageSize={16} className="mx-auto"/>
                </div>
            </div>
        </div>
    );
}

export default SoftwareList;
