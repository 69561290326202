import React, {useEffect, useState} from 'react';
import './NewsAdd.css';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from "draft-js";
import {Input, notification} from "antd";
import {convertToHTML} from 'draft-convert';
import {NewsService} from "../../../services/NewsService";
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {StaticService} from "../../../services/StaticService";
import {FromHtmlConfig} from "../NewsEdit/NewsEdit";
import {Attachment} from "../../../types/StaticAsset";
import AttachmentsDragger from "../../../components/AttachmentsDragger/AttachmentsDragger";

export const uploadCallback = async (file: File) => {
    try {
        const staticAsset = await StaticService.addStaticImage(file);

        return {data: {link: staticAsset.url}};
    } catch (e) {
        return {data: {link: ''}};
    }
}

function NewsAdd() {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [isAdminView, setIsAdminView] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(FromHtmlConfig)(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description) {
            api.error({
                message: `Uzupełnij tytuł i treść`,
                description: 'Tytuł i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const data = {
            title,
            description,
            attachments: attachments.map(x => x.asset)
        }

        NewsService.addNews(data).then(() => {
            api.info({
                message: `Dodano aktualność`,
                description: 'Aktualność została dodana!',
                placement: 'top'
            });
            setTitle('');
            setDescription('');
            setEditorState(EditorState.createEmpty());
            setAttachments([]);
            setFileList([]);
        }).catch((e) => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania aktualności!',
                placement: 'top'
            });
        });
    }

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes('admin'))
            setIsAdminView(true);
    }, []);

    return (
        <div className="content">
            {contextHolder}
            <RetoricBreadcrumbs locations={isAdminView ? Locations['admin/news/new'] : Locations['news/new']}>Dodaj
                aktualność</RetoricBreadcrumbs>

            <Infobox title="Dodawanie aktualności"
                     rows={[
                         'W tej sekcji możesz dodać aktualność dla swoich współpracowników. Po dodaniu, aktualność musi zostać zaakceptowana przez jednego z administratorów zanim pojawi się na stronie głównej.'
                     ]}
                     className="self-stretch"
            />
            <Input size="large" placeholder="Tytuł" value={title} onChange={updateTitle}
                   className="bg-offwhite-important rounded-none placeholder:text-gray-500"/>
            <Editor
                editorState={editorState}
                locale={'pl'}
                wrapperClassName="flex-1 self-stretch border border-gray-200 bg-offwhite"
                editorClassName="px-4 bg-offwhite min-h-[20vh]"
                toolbar={{
                    image: {
                        uploadCallback: uploadCallback,
                        uploadEnabled: true,
                        previewImage: true,
                        alt: {present: true, mandatory: false},
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp',
                    }
                }}
                onEditorStateChange={updateEditorState}
            />
            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments} fileList={fileList}
                                setFileList={setFileList}/>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Dodaj
                aktualność</RetoricButton>
        </div>
    );
}

export default NewsAdd;
