import React, {useEffect, useState} from 'react';
import {Empty, Input, Modal, notification, Popconfirm, Space, Table, Tooltip} from "antd";
import './InfoCategoriesModal.css';
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {ColumnsType} from "antd/es/table";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import TagCloud from "../../../components/TagCloud/TagCloud";
import {InfopageService} from "../../../services/InfopageService";
import {ErrorMessages} from "../../../extensions/ErrorMessages";
import {Infopage, InfopageCategory} from "../../../types/InfopageModels";

export interface InfoCategoriesModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    refresh?: () => void;
}

export interface EntryCategory {
    name: string;
    id?: string;
    entries?: string[];
}

function InfoCategoriesModal({open, setOpen, refresh}: InfoCategoriesModalProps) {
    const [rowEdit, setRowEdit] = useState('');
    const [data, setData] = useState([] as InfopageCategory[]);
    const [newName, setNewName] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [api, contextHolder] = notification.useNotification();


    const refreshCategories = () => {
        InfopageService.getInfopageCategories().then((r) => {
            setData(r);
        });
    }

    const deleteCategory = (id: string) => {
        InfopageService.deleteInfopageCategory(id).then((r) => {
            refreshCategories();
            setRowEdit('');
            setNewName('');

            if (refresh)
                refresh();
        })
            .catch((e: Error) => {
                let error = "Twoje uprawnienia nie pozwalają na usunięcie kategorii!"

                if (e.message === ErrorMessages.categoryDoesNotExists) {
                    error = 'Kategoria nie istnieje!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    const updateCategory = () => {
        if (newName.length < 3) {
            api.error({
                message: `Wystąpił błąd`,
                description: 'Nazwa kategorii musi mieć co najmniej 3 znaki!',
                placement: 'top'
            });
            return;
        }

        const cat = {
            title: newName,
        };

        InfopageService.updateInfopageCategory(rowEdit, cat)
            .then((r) => {
                refreshCategories();
                setRowEdit('');
                setNewName('');

                if (refresh)
                    refresh();

                api.info({
                    message: `Kategoria zaktualizowana`,
                    description: `Nazwa kategorii została zmieniona`,
                    placement: 'top'
                });
            })
            .catch((e: Error) => {
                let error = "Twoje uprawnienia nie pozwalają na usunięcie kategorii!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Kategoria nie została znaleziona!'
                }

                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Nazwa kategorii musi mieć co najmniej 3 znaki!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    const addCategory = () => {
        if (newCategory.length < 3) {
            api.error({
                message: `Wystąpił błąd`,
                description: 'Nazwa kategorii musi mieć co najmniej 3 znaki!',
                placement: 'top'
            });
            return;
        }

        setShowInput(false);
        InfopageService.addInfopageCategory({title: newCategory}).then((r) => {
            refreshCategories();
            setNewCategory('');
            setShowInput(false);

            if (refresh)
                refresh();

            api.info({
                message: `Kategoria dodana`,
                description: `Nowa kategoria została utworzona`,
                placement: 'top'
            });
        }).catch((e: Error) => {
            let error = "Twoje uprawnienia nie pozwalają na usunięcie kategorii!"

            if (e.message === ErrorMessages.notFound) {
                error = 'Kategoria nie została znaleziona!'
            }

            if (e.message === ErrorMessages.nameExists) {
                error = 'Kategoria o takiej nazwie już istnieje!'
            }

            if (e.message === ErrorMessages.validationFailed) {
                error = 'Nazwa kategorii musi mieć co najmniej 3 znaki!'
            }

            api.error({
                message: `Wystąpił błąd`,
                description: error,
                placement: 'top'
            });
        })
    }

    const columns: ColumnsType<InfopageCategory> = [
        {
            title: 'Nazwa',
            dataIndex: 'title',
            key: 'name',
            width: 300,
            render: (value, record) => rowEdit === record.id ?
                <Input defaultValue={value}
                       onChange={(e) => setNewName(e.target.value)}
                       className="rounded-none max-w-[290px]"
                       suffix={<>
                           <Tooltip title="Zapisz">
                               <SaveOutlined onClick={() => updateCategory()}/>
                           </Tooltip>
                           <Tooltip title="Anuluj">
                               <CloseOutlined onClick={() => setRowEdit('')}/>
                           </Tooltip>
                       </>}></Input>
                :
                <span className="block">{value}</span>
        },
        {
            title: 'Liczba infostron',
            key: 'infopages',
            responsive: ['md'],
            render: (_, record) => <span className="block">{record.infopages?.length}</span>
        },
        {
            title: 'Infostrony',
            dataIndex: 'infopages',
            key: 'infopages',
            responsive: ['md'],
            render: (page, record) => <TagCloud tags={page.map((x: Infopage) => x.title)} limit={2}/>,
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edytuj kategorię">
                        <EditOutlined onClick={() => setRowEdit(record.id ?? '')}/>
                    </Tooltip>
                    <Tooltip title="Usuń kategorię">
                        <Popconfirm
                            placement="top"
                            title={`Czy jesteś pewien?`}
                            description={`Czy na pewno chcesz usunąć kategorię ${record.title}?`}
                            okButtonProps={{className: 'bg-brandColor-950 hover:bg-brandColor-800'}}
                            onConfirm={() => deleteCategory(record.id ?? '')}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <DeleteOutlined/>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const closeModal = () => {
        setRowEdit('');
        setShowInput(false);
        setNewName('');
        setNewCategory('');
        setOpen(false);
    }

    useEffect(() => {
        refreshCategories();

        return () => {
            closeModal();
        }
    }, []);

    return (
        <Modal
            title="Kategorie poradników"
            centered footer={[
            <RetoricButton key="back" outline className="mr-4" onClick={() => closeModal()}>
                Zamknij
            </RetoricButton>,
        ]}
            onCancel={() => {
                closeModal();
            }}
            open={open}
            width={1000}
        >
            <div className="flex flex-col gap-4">
                {contextHolder}
                <Infobox title="Zarządzanie kategoriami">
                    <div>
                        <p className="text-slate-600">W tej skecji możesz zarządzać kategoriami poradników.</p>
                    </div>
                </Infobox>
                <Space>
                    <RetoricButton outline className="mr-4" onClick={() => setShowInput(true)}>
                        Dodaj kategorię
                    </RetoricButton>
                </Space>
                <Table columns={columns} dataSource={data} rowKey={'id'}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
                {showInput && <div className="flex flex-col gap-2">
                    <h4 className="text-lg text-gray-700">Dodaj kategorię</h4>
                    <Input placeholder={'Nazwa kategorii'} onChange={(e) => setNewCategory(e.target.value)}
                           className="rounded-none"
                           suffix={
                               <Tooltip title="Utwórz"><PlusOutlined
                                   onClick={() => addCategory()}/></Tooltip>}></Input>
                </div>}
            </div>
        </Modal>
    );
}

export default InfoCategoriesModal;
