import {NewsCardProps} from "../components/NewsCard/NewsCard";
import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {NewsRequest} from "../types/NewsModels";
import {ErrorStatus} from "../types/AuthState";
import {ErrorMessages} from "../extensions/ErrorMessages";

export const NewsService = {
    getNews: async (): Promise<NewsCardProps[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    getNewsEntry: async (id: string): Promise<NewsCardProps> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News/view/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch {
            Logout();
            throw new Error('unauthorized');
        }
    },
    editNewsEntry: async (news: NewsCardProps): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(news)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    getPendingNews: async (): Promise<NewsCardProps[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News/pending`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    addNews: async (news: NewsRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(news)
            });

            if (response.status === 401) {
                Logout();
                return;
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    changeNewsStatus: async (id: string, status: boolean): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News/${id}/status/${status}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 200)
                return Promise.resolve();

            if (response.status === 404)
                return Promise.reject(new Error('notFound'));

            return Promise.reject(new Error('unauthorized'));
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    pinNews: async (id: string, pinned: boolean): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News/${id}/pin/${pinned}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 200)
                return Promise.resolve();

            if (response.status === 404)
                return Promise.reject(new Error(ErrorMessages.notFound));

            return Promise.reject(new Error(ErrorMessages.forbidden));
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteNews: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/News/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 200)
                return Promise.resolve();

            if (response.status === 404)
                return Promise.reject(new Error('notFound'));

            return Promise.reject(new Error('unauthorized'));
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    }
}
