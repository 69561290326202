import {Skeleton, Space, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from "react";
import {Infopage, InfopageTraining, InfopageTrainingEntry} from "../../../../types/InfopageModels";
import {InfopageService} from "../../../../services/InfopageService";
import {InfopageProps} from "../PhoneNumbers/PhoneNumbersInfopage";
import {ColumnsType} from "antd/es/table";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useAuth} from "../../../../extensions/Auth";
import {FormatDateSimpleWithTime, IsFutureDate, IsPastDate} from "../../../../extensions/DateFormatter";
import './TrainingInfopage.css'
import {TrainingMock} from "../TemplateMock";


const TrainingInfopage = ({infopage, isTemplateView}: InfopageProps) => {
    const [page, setPage] = useState({} as InfopageTraining);
    const [loading, setLoading] = useState(false);

    const auth = useAuth();

    const columns: ColumnsType<InfopageTrainingEntry> = [
        {
            title: 'Temat',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Opis',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: (value, record) => <div>{FormatDateSimpleWithTime(value)}</div>
        },
        {
            title: 'Organizator',
            dataIndex: 'trainingProvider',
            key: 'trainingProvider',
        },
        {
            title: 'Lokalizacja',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Akcje',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Więcej informacji">
                        <InfoCircleOutlined onClick={() => auth.navigate(record.infoUrl)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (!isTemplateView) {
            setLoading(true);

            InfopageService.getInfopageById(infopage.id)
                .then((x: Infopage) => {
                    setPage(x as InfopageTraining)
                })
                .finally(() => setLoading(false));
        } else {
            setPage(TrainingMock)
        }

        return () => {
        };
    }, [infopage, isTemplateView]);

    return (
        <div className="self-stretch">
            <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">{page.title}</h4>
            {!loading &&
                <Table dataSource={page.entries} columns={columns} pagination={{position: ['bottomCenter']}}
                       scroll={{x: 768}}
                       rowKey={'id'}
                       locale={{emptyText: "Brak danych"}}
                       rowClassName={(record, index) => {
                           if (IsPastDate(record.date))
                               return 'bg-red-50 hover:bg-red-50';

                           if (IsFutureDate(record.date))
                               return 'bg-green-50 hover:bg-green-50';

                           return ''
                       }}/>
            }
            {loading && <Skeleton active/>}
        </div>
    );
};

export default TrainingInfopage;
