import React, {useEffect, useState} from 'react';
import './SoftwareRedaction.css';
import {Empty, Pagination} from "antd";
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes} from "../../../types/Scopes";
import BlankNewsCard from "../../../components/BlankNewsCard/BlankNewsCards";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {SoftwareService} from "../../../services/SoftwareService";
import SoftwareCard, {SoftwareCardProps} from "../../../components/SoftwareCard/SoftwareCard";
import SoftwareCategoriesModal from "../SoftwareCategoriesModal/SoftwareCategoriesModal";
import {EntryCategory} from "../../Training/TrainingCategoriesModal/TrainingCategoriesModal";

function SoftwareRedaction() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as SoftwareCardProps[]);
    const [softwareData, setSoftwareData] = useState([] as SoftwareCardProps[]);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as EntryCategory[]);

    const filter = (p = 1, input = data) => setSoftwareData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    const refreshData = (withLoad = true) => {
        setLoading(withLoad);
        SoftwareService.getSoftware().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });

        SoftwareService.getCategories().then((r) => {
            setCategories(r);
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SOFTWARE);

        refreshData();

        return () => {
        };
    }, [auth, page]);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/software']}>Zarządzaj oprogramowaniem</RetoricBreadcrumbs>
            <div className="flex flex-row lg:justify-start justify-center lg:self-start self-stretch">
                <RetoricButton type={'primary'} outline onClick={() => setModalOpen(true)}
                               className="mr-4 min-w-[150px]">Zarządzaj kategoriami</RetoricButton>
                <RetoricButton type={'primary'} outline onClick={() => auth.navigate('/admin/software/new')}
                               className="mr-4 min-w-[150px] lg:hidden">Dodaj oprogramowanie</RetoricButton>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 self-stretch">
                {!loading && softwareData.map((software, index) => <SoftwareCard
                    key={`software-${software.id}`} {...software}
                    categories={categories}
                    categoryName={categories.find((x) => x.id === software.categoryId)?.name} isRedact
                    refresh={() => refreshData(false)}/>)}
                {!loading && softwareData.length === 0 &&
                    <Empty className="lg:col-span-2" description="Brak wyników"/>}
                {loading && [null, null, null, null].map((item, index) => <BlankNewsCard
                    key={`placeholder-training-${index}`}/>)}
            </div>
            <Pagination defaultCurrent={1} total={data.length} pageSize={10} current={page}
                        onChange={updatePagination}
                        showSizeChanger={false}/>
            <SoftwareCategoriesModal open={modalOpen} setOpen={setModalOpen}
                                     refresh={() => refreshData(false)}/>
        </div>
    );
}

export default SoftwareRedaction;
