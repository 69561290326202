import React from 'react';
import './Delegations.css';
import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../extensions/Locations";

function Delegations() {
    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['delegations']}>Delegacje</RetoricBreadcrumbs>
        </div>
    );
}

export default Delegations;
