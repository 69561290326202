import React, {useEffect, useState} from 'react';
import './InfoAdd.css';
import {useAuth} from "../../../extensions/Auth";
import {Form, Input, notification, Radio, Select} from "antd";
import {AdminScopes} from "../../../types/Scopes";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {InfopageService} from "../../../services/InfopageService";
import {Infopage, InfopageCategory, InfopageRequest, InfopageType} from "../../../types/InfopageModels";
import {InfopageTypeMapper} from "../InfopageList/InfopageList";
import BasicInfopage from "../InfopageTemplates/Basic/BasicInfopage";
import PhoneNumbersInfopage from "../InfopageTemplates/PhoneNumbers/PhoneNumbersInfopage";
import TrainingInfopage from "../InfopageTemplates/Training/TrainingInfopage";
import ChangelogInfopage from "../InfopageTemplates/Changelog/ChangelogInfopage";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import Infobox from "../../../designsystems/Infobox/Infobox";
import {ErrorMessages} from "../../../extensions/ErrorMessages";

const GetMock = (type: InfopageType) => {
    switch (type) {
        case InfopageType.Basic:
            return <BasicInfopage isTemplateView infopage={{} as Infopage}/>;
        case InfopageType.PhoneExtensions:
            return <PhoneNumbersInfopage isTemplateView infopage={{id: '123'} as Infopage}/>;
        case InfopageType.Training:
            return <TrainingInfopage isTemplateView infopage={{} as Infopage}/>;
        case InfopageType.Changelog:
            return <ChangelogInfopage isTemplateView infopage={{} as Infopage}/>;
    }
}

function InfoAdd() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [selectedType, setSelectedType] = useState(InfopageType.Basic);
    const [categories, setCategories] = useState([] as InfopageCategory[]);

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);

        InfopageService.getInfopageCategories().then((r) => {
            setCategories(r);
        });

        form.setFieldValue('type', InfopageType.Basic);
        form.setFieldValue('isHidden', true);

        return () => {
        };
    }, [auth]);

    const publish = () => {
        const formValues = form.getFieldsValue();

        if (!formValues.title) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł nie może być pusty!',
                placement: 'top'
            });
            return;
        }

        if (formValues.length > 50) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł musi mieć maksymalnie 50 znaków!',
                placement: 'top'
            });
            return;
        }

        const payload: InfopageRequest = {
            title: formValues.title,
            categoryId: formValues.categoryId.length === 0 ? undefined : formValues.categoryId,
            type: formValues.type,
            isHidden: formValues.isHidden
        };

        InfopageService.addInfopage(payload)
            .then(() => {
                api.info({
                    message: `Dodano stronę`,
                    description: 'Strona została dodana!',
                    placement: 'top'
                });

                form.resetFields();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na dodanie strony!"


                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Nazwa strony musi mieć co najmniej 3 znaki!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/info/new']}>Dodaj stronę informacyjną</RetoricBreadcrumbs>
            {contextHolder}
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 min-w-full">
                <Form
                    layout={'vertical'}
                    form={form}
                    className={"min-w-full col-span-3 lg:col-span-1"}
                >
                    <RetoricCard className="bg-offwhite">
                        <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">Konfiguracja
                            strony</h4>
                        <Infobox title={"Strony informacyjne"} className="mb-4" rows={[
                            'Strona informacyjna będzie wyświetlana w sekcji "Informacje" w momencie, gdy zostanie dodana do jednej z kategorii, a jej status będzie ustawiony jako widoczny.',
                            'Po dodaniu strony informacyjnej, będziesz mógł dodać do niej informacje.'
                        ]}/>
                        <Form.Item label="Tytuł" name="title"
                                   rules={[{required: true, message: "Tytuł jest wymagany"}]}>
                            <Input size="large" placeholder="Tytuł strony informacyjnej"
                                   className="bg-offwhite rounded-none"/>
                        </Form.Item>
                        <Form.Item label="Szablon" name="type" rules={[{required: true}]}>
                            <Select style={{width: '100%'}} allowClear onChange={(e) => setSelectedType(e)}>
                                <Select.Option
                                    value={InfopageType.Basic}>{InfopageTypeMapper(InfopageType.Basic)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.PhoneExtensions}>{InfopageTypeMapper(InfopageType.PhoneExtensions)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.Training}>{InfopageTypeMapper(InfopageType.Training)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.Changelog}>{InfopageTypeMapper(InfopageType.Changelog)}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Kategoria" name="categoryId">
                            <Select style={{width: '100%'}} allowClear>
                                <Select.Option value={''}>Brak kategorii</Select.Option>
                                {categories.map((category) => <Select.Option value={category.id}
                                                                             key={category.id}>{category.title}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Czy strona powinna być widoczna po utworzeniu?" name="isHidden">
                            <Radio.Group>
                                <Radio value={true}>Nie</Radio>
                                <Radio value={false}>Tak</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Dodaj
                            stronę</RetoricButton>

                    </RetoricCard>
                </Form>
                <div className="flex flex-col justify-stretch self-stretch col-span-3 lg:col-span-2">
                    <RetoricCard className="rounded bg-offwhite">
                        <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">Podgląd
                            szablonu</h4>
                        {GetMock(selectedType)}
                    </RetoricCard>
                </div>
            </div>
        </div>
    );
}

export default InfoAdd;
