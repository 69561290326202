import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from "@ant-design/icons";
import React from "react";
import {message, UploadProps} from "antd";
import {Attachment} from "../../../types/StaticAsset";
import SignalistService from "../../../services/SignalistService";

export interface AttachmentsDraggerProps {
    attachments: Attachment[];
    setAttachments: (attachments: Attachment[]) => void;
    fileList: any[];
    setFileList: (fileList: any[]) => void;
}

export default function SignalistAttachmentsDragger({
                                                        attachments,
                                                        setAttachments,
                                                        fileList,
                                                        setFileList
                                                    }: AttachmentsDraggerProps) {
    const draggerProps: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 5,
        fileList: fileList,
        beforeUpload(file) {
            SignalistService.addAttachment(file)
                .then(x => {
                    const attachment: Attachment = {
                        uploadId: file.uid,
                        asset: x
                    };

                    setAttachments([...attachments, attachment]);
                    setFileList([...fileList, file]);
                    message.success(`Załącznik o nazwie ${file.name} został dodany!`);
                })
                .catch(x => {
                    message.error(`Załącznik o nazwie ${file.name} nie został dodany!`);
                })

            return false;
        },
        onRemove(file) {
            const attachment = attachments.find(x => x.uploadId === file.uid);

            if (!attachment || !attachment.asset.name)
                return;

            setAttachments(attachments.filter(x => x.uploadId !== file.uid));
            setFileList(fileList.filter(x => x.uid !== file.uid));
        }
    };

    return (
        <Dragger className="self-stretch" {...draggerProps}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Dodaj załączniki</p>
            <p className="ant-upload-hint">
                Naciśnij lub przeciągnij pliki by dodać je jako załącznik. Maksymalny rozmiar każdego pliku to 5MB.
                Możesz dodać maksymalnie 5 załączników.
            </p>
            <p className="ant-upload-hint">
                (Wspierane typy plików: .jpg, .jpeg, .png, .gif, .svg, .webp, .pdf, .doc, .docx, .xls, .xlsx, .ppt,
                .pptx, .odt, .ods, .odp, .txt, .rtf, .csv)
            </p>
        </Dragger>
    )
}
