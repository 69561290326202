import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {ErrorObject, RedactedUser, SuccessObject, User} from "../types/AuthState";
import {FileObjectType} from "react-image-upload";

const ErrorMapper = (errors: string[]) => {
    const errorObject: ErrorObject = {error: ''};

    if (errors.includes("User with the given email already exists!"))
        errorObject.error = 'Użytkownik o podanym adresie email już istnieje!';
    else if (errors.includes("The value must be a valid email address"))
        errorObject.error = 'Podany adres email nie jest poprawny!';
    else
        errorObject.error = 'Formularz zawiera błędy, upewnij się, że wszystkie wymagane pola zostały wypełnione!';

    return errorObject;
}

export const UserService = {
    getUsers: async (): Promise<User[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getUser: async (id: string): Promise<User | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getRedactedUser: async (id: string): Promise<RedactedUser | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/redacted/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    addUser: async (user: User): Promise<User | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(user)
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 400)
                return ErrorMapper((await response.json()).errors);

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    editUser: async (user: User, email: string): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/${email}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(user)
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    deleteUser: async (email: string): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/${email}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    triggerImport: async (fileId: string, overwrite: boolean, regeneratePasswords: boolean): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/import/${fileId}/${overwrite}/${regeneratePasswords}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono pliku!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getUserAvatar: (avatar: string | undefined, email: string): string => {
        if (avatar && !avatar.startsWith("http"))
            return `${BACKEND_BASE}/User/avatar/img/${avatar}`;
        else if (email)
            return `${BACKEND_BASE}/User/avatar/${email}`;
        else
            return `${BACKEND_BASE}/User/avatar/img/placeholder.png`
    },
    updateAvatar: async (file: FileObjectType): Promise<void> => {
        try {
            const data = new FormData();
            data.append('file', file.file);

            const response = await fetch(`${BACKEND_BASE}/User/avatar`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: data
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return Promise.resolve();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    }
}
