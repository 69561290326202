import {Empty, Skeleton} from 'antd';
import {ContactRecord, ContactRecordType, Infopage, InfopagePhoneExtensions} from "../../../../types/InfopageModels";
import React, {useEffect, useState} from "react";
import {InfopageService} from "../../../../services/InfopageService";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import {PhoneNumbersMock} from "../TemplateMock";

export interface InfopageProps {
    infopage: Infopage;
    isTemplateView?: boolean;
}

export const GetPhoneExtensionElement = (record: ContactRecord) => {
    switch (record.type) {
        case ContactRecordType.Phone:
            return <div className="grid grid-cols-3 mb-1">
                <span>Tel:</span>
                <div className="col-span-2"><a href={`tel:${record.value}`}>{record.value}</a></div>
            </div>;
        case ContactRecordType.Email:
            return <div className="grid grid-cols-3 mb-1">
                <span>Email:</span>
                <div className="col-span-2"><a href={`mailto:${record.value}`}>{record.value}</a></div>
            </div>;
        case ContactRecordType.Web:
            return <div className="grid grid-cols-3 mb-1">
                <div>WWW:</div>
                <div className="col-span-2"><a href={`${record.value}`} target={'_blank'}
                                               rel={'noreferrer'}>{record.value}</a></div>
            </div>;
        default:
            return <span>{record.value}</span>
    }
}

const PhoneNumbersInfopage = ({infopage, isTemplateView}: InfopageProps) => {
    const [page, setPage] = useState({} as InfopagePhoneExtensions);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isTemplateView) {
            setLoading(true);

            InfopageService.getInfopageById(infopage.id)
                .then((x: Infopage) => {
                    setPage(x as InfopagePhoneExtensions)
                })
                .finally(() => setLoading(false));
        } else {
            setPage(PhoneNumbersMock)
        }

        return () => {
        };
    }, [infopage, isTemplateView]);

    return (
        <div className="self-stretch">
            <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">{page.title}</h4>
            {!loading &&
                <div className="flex flex-col justify-stretch gap-4">
                    {page.entries?.map((entry) => (
                        <div key={`${infopage.id}-${entry.id}`}>
                            {entry.extensions?.length > 0 && <RetoricCard className="flex flex-col justify-stretch">
                                <h4 className="lg:text-xl text-lg text-slate-800 text-center lg:text-left self-stretch mb-2">{entry.title}</h4>
                                <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                                    {entry.extensions.map((extension) => <RetoricCard
                                        key={`${infopage.id}-${entry.id}-${extension.id}`}
                                        className="flex flex-col justify-stretch bg-gray-100/70">
                                        <h4 className="lg:text-lg text-md text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-2">{extension.title}</h4>
                                        {extension.extensions.map((record, index) => (
                                            <div className="ml-4"
                                                 key={`${infopage.id}-${entry.id}-${extension.id}-${record.value}-${index}`}>
                                                {GetPhoneExtensionElement(record)}
                                            </div>))}
                                    </RetoricCard>)}
                                </div>
                            </RetoricCard>}
                        </div>
                    ))}
                    {(!page.entries || page.entries?.length === 0) &&
                        <Empty description="Ta strona nie została jeszcze skonfigurowana"/>}
                </div>
            }
            {loading && <Skeleton active/>}
        </div>
    );
};

export default PhoneNumbersInfopage;
