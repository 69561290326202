import React, {useEffect, useState} from 'react';
import './UserEdit.css';
import {useAuth} from "../../../extensions/Auth";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Input, notification, Select} from "antd";
import {AdminScopes, GUID_EMPTY} from "../../../types/Scopes";
import {ScopeMapper} from "../UsersList/UsersList";
import {UserService} from "../../../services/UserService";
import {User} from "../../../types/AuthState";
import {useParams} from "react-router-dom";
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";

function UserEdit() {
    const auth = useAuth();
    const [users, setUsers] = useState([] as any[]);
    let {userEmail} = useParams();

    const [api, contextHolder] = notification.useNotification();

    const [form, setForm] = useState({scopes: []} as any);

    const updateForm = (e: any, field: string) => {
        let v = e.target?.value ?? e;

        if (v === null || v === undefined)
            v = '';

        setForm({...form, [field]: v});
    }

    const updateFormScopes = (e: any) => {
        setForm({...form, scopes: e});
    }

    const publish = () => {
        const keys = ['firstName', 'lastName', 'holidayAllowance', 'organisation', 'position', 'email'];

        if (!form.firstName || !form.lastName || !form.holidayAllowance || !form.organisation || !form.position || !form.email) {
            const errors = [];
            if (!form.firstName) errors.push('Imię');
            if (!form.lastName) errors.push('Nazwisko');
            if (!form.holidayAllowance) errors.push('Dni urlopowe');
            if (!form.organisation) errors.push('Dział');
            if (!form.position) errors.push('Stanowisko');
            if (!form.email) errors.push('Email');

            api.error({
                message: `Uzupełnij wymagane pola`,
                description: `${errors.join(', ')} ${errors.length === 1 ? 'jest' : 'są'} wymagane!`,
                placement: 'top'
            });
            return;
        }

        for (const key of keys) {
            if (form[key].length > 70) {
                api.error({
                    message: `Zbyt długa wartość`,
                    description: `${key} może mieć maksymalnie 70 znaków!`,
                    placement: 'top'
                });
                return;
            }
        }

        const user: User = {
            isAdmin: form.scopes.length > 0,
            isAuthenticated: false,
            name: `${form.firstName} ${form.lastName}`,
            firstName: form.firstName,
            lastName: form.lastName,
            holidayAllowance: form.holidayAllowance,
            organisation: form.organisation,
            position: form.position,
            email: form.email,
            scopes: form.scopes,
            avatar: form.avatar ?? '',
            managerId: form.managerId ?? null
        }

        if (userEmail) {
            UserService.editUser(user, userEmail)
                .then((x) => addEditCallback(x, keys))
                .catch((e) => {
                    api.error({
                        message: `Błąd`,
                        description: 'Wystąpił błąd podczas dodawania użytkownika!',
                        placement: 'top'
                    });
                })
        }
    }

    const addEditCallback = (x: any, keys: string[]) => {
        if ("error" in x) {
            api.error({
                message: `Błąd`,
                description: x.error,
                placement: 'top'
            });
            return;
        }

        api.info({
            message: `Zaktualizowano użytkownika`,
            description: `Użytkownik został zaktualizowany!`,
            placement: 'top'
        });
    }

    const locations = [...Locations['admin/users'], {
        title: `Edytuj (${userEmail})`,
        path: `/admin/users/edit/${userEmail}`
    }]

    useEffect(() => {
        auth.verifyAuth(AdminScopes.USERS);

        UserService.getUsers().then((x) => {
            if ("error" in x) {
                return;
            }

            setUsers([{id: GUID_EMPTY, firstName: 'Brak', lastName: 'menadżera', email: ''}, ...x]);
        });

        if (userEmail) {
            UserService.getUser(userEmail).then((x) => {
                if ("error" in x) {
                    api.error({
                        message: `Błąd`,
                        description: x.error,
                        placement: 'top'
                    });
                    auth.navigate('/users');
                    return;
                }

                setForm({
                    firstName: x.firstName,
                    lastName: x.lastName,
                    holidayAllowance: x.holidayAllowance,
                    organisation: x.organisation,
                    position: x.position,
                    email: x.email,
                    scopes: x.scopes,
                    avatar: x.avatar,
                    managerId: x.managerId
                });
            });
        }

        return () => {
        };
    }, [auth, api, userEmail]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations}>Zarządzanie użytkownikami</RetoricBreadcrumbs>
            {contextHolder}
            <Infobox title="Dodawanie użytkownika"
                     className="self-stretch"
                     rows={[
                         'W tej sekcji możesz zmienić informacje użytkownika.',
                         'Pola oznaczone gwiazdką są wymagane'
                     ]}
            />
            <RetoricCard className="bg-offwhite self-stretch">
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                    <div>
                        <label>Imię <b>*</b></label>
                        <Input size="large" placeholder="Jan" value={form.firstName}
                               onChange={(e: any) => updateForm(e, 'firstName')}
                               className="bg-offwhite rounded-none"
                               required/>
                    </div>
                    <div>
                        <label>Nazwisko <b>*</b></label>
                        <Input size="large" placeholder="Nowak" value={form['lastName']}
                               onChange={(e: any) => updateForm(e, 'lastName')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div>
                        <label>Dział <b>*</b></label>
                        <Input size="large" placeholder="Administracja" value={form['organisation']}
                               onChange={(e: any) => updateForm(e, 'organisation')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div>
                        <label>Stanowisko <b>*</b></label>
                        <Input size="large" placeholder="Kierownik działu..." value={form['position']}
                               onChange={(e: any) => updateForm(e, 'position')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div>
                        <label>Adres email <b>*</b></label>
                        <Input size="large" placeholder="jan@nowak.pl" value={form['email']}
                               onChange={(e: any) => updateForm(e, 'email')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div>
                        <label>Liczba dni urlopu (w roku) <b>*</b></label>
                        <Input size="large" type={'number'} placeholder="25" value={form['holidayAllowance']}
                               onChange={(e: any) => updateForm(e, 'holidayAllowance')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div className="lg:col-span-3">
                        <label>Link do zdjęcia użytkownika</label>
                        <Input size="large" placeholder="https://..." value={form['avatar']}
                               onChange={(e: any) => updateForm(e, 'avatar')}
                               className="bg-offwhite rounded-none"/>
                    </div>
                    <div className="lg:col-span-3">
                        <label>Bezpośredni przełożony</label>
                        <Select
                            className="bg-offwhite rounded-none"
                            size={'large'}
                            placeholder="Wybierz bezpośredniego przełożonego"
                            value={form['managerId']}
                            allowClear
                            onClear={() => updateForm(GUID_EMPTY, 'managerId')}
                            onChange={(e: any) => updateForm(e, 'managerId')}
                            style={{width: '100%'}}
                            options={users.map((user: User) => {
                                return ({
                                    value: user.id,
                                    label: `${user.firstName} ${user.lastName} ${user.email}`
                                });
                            })}
                        />
                    </div>
                    <div className="lg:col-span-3">
                        <label>Uprawnienia administracyjne</label>
                        <Select
                            className="bg-offwhite rounded-none"
                            mode="multiple"
                            size={'large'}
                            placeholder="Wybierz uprawnienia użytkownika"
                            value={form.scopes}
                            onChange={(e: any) => updateFormScopes(e)}
                            style={{width: '100%'}}
                            options={Object.keys(AdminScopes).map((key: number | string) => {
                                return ({
                                    value: AdminScopes[key as keyof typeof AdminScopes],
                                    label: ScopeMapper(AdminScopes[key as keyof typeof AdminScopes])
                                });
                            })}
                        />
                    </div>
                </div>
                <RetoricButton className="mt-4"
                               onClick={() => publish()}>Zaktualizuj</RetoricButton>
            </RetoricCard>
        </div>
    );
}

export default UserEdit;
