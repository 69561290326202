import {Card} from "antd";
import {
    AppstoreAddOutlined,
    CalendarOutlined,
    ContainerOutlined,
    InfoCircleOutlined,
    RobotOutlined,
    SecurityScanOutlined
} from "@ant-design/icons";
import React from "react";
import AuthState from "../../types/AuthState";

export interface HeaderNavProps {
    auth: AuthState;
}

export default function HeaderNav({auth}: HeaderNavProps) {
    return (
        <div className={'content-options'}>
            <Card
                hoverable
                onClick={() => auth.navigate('/')}
                className={auth.route === '/' ? 'active-underline' : ''}
                cover={<ContainerOutlined className={'content-options__card-icon'}/>}
            >
                <span className="text-xl text-center block p-2">Aktualności</span>
            </Card>
            <Card
                hoverable
                onClick={() => auth.navigate('/aiassistant')}
                className={auth.route === '/aiassistant' ? 'active-underline' : ''}
                cover={<RobotOutlined className={'content-options__card-icon'}/>}
            >
                <span className="text-xl text-center block p-2">Asystent AI</span>
            </Card>
            <Card
                hoverable
                onClick={() => auth.navigate('/information')}
                className={auth.route === '/information' ? 'active-underline' : ''}
                cover={<InfoCircleOutlined className={'content-options__card-icon'}/>}
            >
                <span className="text-xl text-center block p-2">Informacje</span>
            </Card>
            <Card
                hoverable
                onClick={() => auth.navigate('/modules')}
                className={auth.route.startsWith('/modules') ? 'active-underline' : ''}
                cover={<AppstoreAddOutlined className={'content-options__card-icon'}/>}
            >
                <span className="text-xl text-center block p-2">Moduły</span>
            </Card>
            <Card
                hoverable
                onClick={() => auth.navigate('/calendar')}
                className={auth.route === '/calendar' ? 'active-underline' : ''}
                cover={<CalendarOutlined className={`content-options__card-icon`}/>}
            >
                <span className="text-xl text-center block p-2">Kalendarz</span>
            </Card>
            <Card
                hoverable
                onClick={() => auth.navigate('/training')}
                className={auth.route === '/training' ? 'active-underline' : ''}
                cover={<SecurityScanOutlined className={'content-options__card-icon'}/>}
            >
                <span className="text-xl text-center block p-2">Poradniki</span>
            </Card>
        </div>
    )
}
