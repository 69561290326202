import React, {useEffect, useState} from "react";
import {Infopage, InfopageChangelog, InfopageChangelogEntry} from "../../../../types/InfopageModels";
import {InfopageService} from "../../../../services/InfopageService";
import {InfopageProps} from "../PhoneNumbers/PhoneNumbersInfopage";
import './ChangelogInfopage.css'
import {Skeleton, Table} from "antd";
import {FormatDateSimple} from "../../../../extensions/DateFormatter";
import {ColumnsType} from "antd/es/table";
import {ChangelogMock} from "../TemplateMock";


const ChangelogInfopage = ({infopage, isTemplateView}: InfopageProps) => {
    const [page, setPage] = useState({} as InfopageChangelog);
    const [loading, setLoading] = useState(false);

    const columns: ColumnsType<InfopageChangelogEntry> = [
        {
            title: 'Nazwa',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: (value, record) => <div>{FormatDateSimple(value)}</div>
        },
        {
            title: 'Zmiany',
            dataIndex: 'description',
            key: 'description',
            width: '70%',
            render: (text: string) => (
                <ul>
                    {text?.split('\n')?.map((change: string) => (
                        <li key={change}>{change}</li>
                    ))}
                </ul>
            ),
        },
    ];

    useEffect(() => {
        if (!isTemplateView) {
            setLoading(true);

            InfopageService.getInfopageById(infopage.id)
                .then((x: Infopage) => {
                    setPage(x as InfopageChangelog)
                })
                .finally(() => setLoading(false));
        } else {
            setPage(ChangelogMock)
        }

        return () => {
        };
    }, [infopage, isTemplateView]);

    return (
        <div className="self-stretch">
            <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">{page.title}</h4>
            {!loading && <Table dataSource={page.entries} columns={columns} locale={{emptyText: "Brak danych"}}
                                rowKey={'id'}
                                pagination={{position: ['bottomCenter']}}/>}
            {loading && <Skeleton active/>}
        </div>
    );
};

export default ChangelogInfopage;
