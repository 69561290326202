import {createBrowserRouter} from "react-router-dom";
import NewsList from "../screens/News/NewsList/NewsList";
import SoftwareList from "../screens/Software/SoftwareList/SoftwareList";
import Delegations from "../screens/Delegations/Delegations";
import Holiday from "../screens/Holiday/Holiday";
import Mail from "../screens/Mail/Mail";
import TrainingList from "../screens/Training/TrainingList/TrainingList";
import NewsAdd from "../screens/News/NewsAdd/NewsAdd";
import Profile from "../screens/Profile/Profile";
import TrainingAdd from "../screens/Training/TrainingAdd/TrainingAdd";
import TrainingView from "../screens/Training/TrainingView/TrainingView";
import AiAssistant from "../screens/AiAssistant/AiAssistant";
import Modules from "../screens/Modules/Modules";
import Info from "../screens/Info/Info";
import React from "react";
import AdminView from "../screens/AdminView/AdminView";
import ModuleInDevelopment from "../screens/Modules/ModuleInDevelopment/ModuleInDevelopment";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <NewsList/>,
    },
    {
        path: '/admin/*',
        element: <AdminView/>
    },
    {
        path: "/news/new",
        element: <NewsAdd/>,
    },
    {
        path: "/information",
        element: <Info/>,
    },
    {
        path: "/information/:catId",
        element: <Info/>,
    },
    {
        path: "/information/:catId/:pageId",
        element: <Info/>,
    },
    {
        path: "/aiassistant",
        element: <AiAssistant/>,
    },
    {
        path: "/mail",
        element: <Mail/>,
    },
    {
        path: "/modules",
        element: <Modules/>,
    },
    {
        path: "/modules/holiday",
        element: <Holiday/>,
    },
    {
        path: "/modules/software",
        element: <SoftwareList/>,
    },
    {
        path: "/modules/delegations",
        element: <Delegations/>,
    },
    {
        path: "/modules/in-development/:moduleName",
        element: <ModuleInDevelopment/>,
    },
    {
        path: "/training/new",
        element: <TrainingAdd/>,
    },
    {
        path: "/training/view/:id",
        element: <TrainingView/>,
    },
    {
        path: "/training",
        element: <TrainingList/>,
    },
    {
        path: "/profile",
        element: <Profile/>,
    },
    {
        path: "/calendar",
        element: <ModuleInDevelopment name="calendar"/>,
    },
]);

export default Router;
