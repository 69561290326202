import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {AiChat, AiChatRedacted, TokenUsageWithPrice} from "../types/AiChat";

export const AiChatService = {
    getChat: async (id: string): Promise<AiChat> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getChats: async (): Promise<AiChatRedacted[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getPerUserUsage: async (from?: Date, to?: Date): Promise<any> => {
        try {
            let url = `${BACKEND_BASE}/Chatbot/admin/usage/detailed`;

            if (from && to)
                url += `/${from.toISOString()}/${to.toISOString()}`;
            else if (from)
                url += `/${from.toISOString()}`;

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getTotalUsage: async (from?: Date, to?: Date): Promise<TokenUsageWithPrice> => {
        try {
            let url = `${BACKEND_BASE}/Chatbot/admin/usage`;

            if (from && to)
                url += `/${from.toISOString()}/${to.toISOString()}`;
            else if (from)
                url += `/${from.toISOString()}`;

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    }
}
