import {SoftwareCardProps} from "../components/SoftwareCard/SoftwareCard";
import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {SoftwareRequest} from "../types/SoftwareModels";
import {EntryCategory} from "../screens/Training/TrainingCategoriesModal/TrainingCategoriesModal";
import {ErrorStatus} from "../types/AuthState";

export const SoftwareService = {
    getSoftware: async (): Promise<SoftwareCardProps[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getSoftwareById: async (id: string): Promise<SoftwareCardProps> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    addSoftware: async (software: SoftwareRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(software)
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    updateSoftware: async (software: SoftwareCardProps): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(software)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 401) {
                return Promise.reject(new Error('unauthorized'));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    deleteSoftware: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                return Promise.reject(new Error('unauthorized'));
            }

            return Promise.resolve();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getCategories: async (): Promise<EntryCategory[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/categories`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch {
            throw new Error('unauthorized');
        }
    },
    addCategory: async (category: EntryCategory): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/categories`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    updateCategory: async (category: EntryCategory): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/categories`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    deleteCategory: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/categories/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 422) {
                return Promise.reject(new Error('categoryDoesNotExists'));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    },
    updateSoftwareCategory: async (softwareId: string, categoryId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Software/${softwareId}/category/${categoryId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 403) {
                return Promise.reject(new Error('forbidden'));
            }

            return Promise.resolve();
        } catch {
            throw new Error('unauthorized');
        }
    }
}
