import React, {useEffect, useState} from 'react';
import './SignalistList.css';
import {Empty, notification, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes} from "../../../types/Scopes";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {FormatDateSimpleWithTime} from "../../../extensions/DateFormatter";
import {InfoCircleOutlined,} from "@ant-design/icons";
import SignalistService from "../../../services/SignalistService";
import {Report, Reports, ReportState} from "../../../types/Signalist";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";

export const ReportStateMapper = (state: ReportState) => {
    switch (state) {
        case ReportState.New:
            return <span className="text-orange-600">Nowe</span>;
        case ReportState.Read:
            return <span className="text-blue-600">Odczytano</span>;
        case ReportState.Answered:
            return <span className="text-blue-600">Nowa odpowiedź</span>;
        case ReportState.Investigation:
            return <span>W trakcie wyjaśniania</span>;
        case ReportState.Closed:
            return <span className="text-gray-600">Zamknięte</span>;
        case ReportState.Resolved:
            return <span className="text-green-600">Rozwiązane</span>;
    }
}

function SignalistList() {
    const [api, contextHolder] = notification.useNotification();
    const [categories, setCategories] = useState([] as Reports[]);
    const [types, setTypes] = useState([] as Reports[]);
    const [reports, setReports] = useState([] as Report[]);
    const auth = useAuth();

    const getCategory = (id: string) => {
        const cat = categories.find(x => x.id === id);

        return cat ? cat.title : 'Nieznana';
    }

    const getReporterStatus = (id: string) => {
        const cat = types.find(x => x.id === id);

        return cat ? cat.title : 'Nieznany';
    }

    const columns: ColumnsType<Report> = [
        {
            title: 'Data utworzenia',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value, record) => <span>{FormatDateSimpleWithTime(value)}</span>
        },
        {
            title: 'Tytuł',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) => <span>{value}</span>
        },
        {
            title: 'Liczba wiadomości',
            dataIndex: 'messages',
            key: 'messages',
            responsive: ['lg'],
            render: (value, record) => <span>{value.length}</span>
        },
        {
            title: 'Liczba załączników',
            dataIndex: 'attachments',
            key: 'attachments',
            responsive: ['lg'],
            render: (value, record) => <span>{value.length}</span>
        },
        {
            title: 'Status zgłoszenia',
            dataIndex: 'status',
            key: 'status',
            render: (value, record) => <span>{ReportStateMapper(value)}</span>
        },
        {
            title: 'Kategoria',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (value, record) => <span>{getCategory(value)}</span>
        },
        {
            title: 'Status zgłaszającego',
            dataIndex: 'reportingStatusId',
            key: 'reportingStatusId',
            render: (value, record) => <span>{getReporterStatus(value)}</span>
        },
        {
            title: 'Tryb zgłoszenia',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => <span>{value ? 'Imienny' : 'Anonimowy'}</span>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title={'Otwórz raport'}>
                        <InfoCircleOutlined className="cursor-pointer" onClick={() => {
                            auth.navigate('/admin/signalist/view/' + record.id);
                        }}/>
                    </Tooltip>
                </Space>
            ),
        }
    ];

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SIGNALIST);

        SignalistService.getReportCategories()
            .then(x => {
                setCategories(x);
            })
            .catch(err => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać kategorii zgłoszeń',
                    placement: 'top'
                })
            })
            .then(x => {
                SignalistService.getTypes()
                    .then(x => {
                        setTypes(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać typów osób zgłaszających',
                            placement: 'top'
                        })
                    })
            })
            .then(x => {
                SignalistService.getReports()
                    .then(x => {
                        setReports(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać zgłoszeń',
                            placement: 'top'
                        })
                    })
            })

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/signalist']}>Zarządzanie anonimowymi
                zgłoszeniami</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                {contextHolder}
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton type={'primary'} outline onClick={() => auth.navigate('/admin/signalist/new')}
                                       className="mr-4 min-w-[150px] lg:hidden">Dodaj zgłoszenie</RetoricButton>
                    </Space>
                    <div className={'infopages__new'}></div>
                </div>
                <Table columns={columns} dataSource={reports} rowKey={'id'}
                       scroll={{x: 768}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            </RetoricCard>
        </div>
    );
}

export default SignalistList;
