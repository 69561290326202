import React, {useEffect, useState} from 'react';
import './UsersList.css';
import {UserService} from "../../../services/UserService";
import {Empty, Input, notification, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import {User} from "../../../types/AuthState";
import TagCloud from "../../../components/TagCloud/TagCloud";
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes} from "../../../types/Scopes";
import UserDrawer from "../UserDrawer/UserDrawer";
import UserImportModal from "../UserImportModal/UserImportModal";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";

export const ScopeMapper = (value: number) => {
    switch (value) {
        case 1:
            return 'Zarządzanie systemem';
        case 2:
            return 'Zarządzanie aktualnościami';
        case 4:
            return 'Zarządzanie użytkownikami';
        case 8:
            return 'Zarządzanie oprogramowaniem';
        case 16:
            return 'Zarządzanie poradnikami';
        case 32:
            return 'Zarządzanie stronami info';
        case 64:
            return 'Zarządzanie Signalist';
        default:
            return 'Nieznany';
    }
}

const searchFilter = (x: User, searchTerm: string) => {
    return x.name.toLowerCase().includes(searchTerm) ||
        x.position.toLowerCase().includes(searchTerm) ||
        x.email.toLowerCase().includes(searchTerm);
}

function UsersList() {
    const [userList, setUserList] = useState([] as User[]);
    const [filteredUserList, setFilteredUserList] = useState([] as User[]);
    const [search, setSearch] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({} as User);
    const [userImportModalOpen, setUserImportModalOpen] = useState(false);
    const [userDeleteModal, setUserDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [api, contextHolder] = notification.useNotification();
    const auth = useAuth();

    const deleteUser = (email: string) => {
        UserService.deleteUser(email).then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            const newList = filteredUserList.filter(x => x.email !== email);
            setFilteredUserList(newList);
            setUserList(userList.filter(x => x.email !== email));

            api.info({
                message: `Użytkownik usunięty`,
                description: `Użytkownik ${email} został usunięty z systemu`,
                placement: 'top'
            });
        });
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Imię',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Nazwisko',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Dział',
            dataIndex: 'organisation',
            key: 'organisation',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Stanowisko',
            dataIndex: 'position',
            key: 'position',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{value}</span>
        },
        {
            title: 'Uprawnienia',
            dataIndex: 'scopes',
            key: 'scopes',
            responsive: ['md'],
            render: (values: number[]) => <TagCloud tags={values.map(x => ScopeMapper(x))} limit={3}/>,
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edytuj użytkownika">
                        <EditOutlined onClick={() => auth.navigate(`/admin/users/edit/${record.email}`)}/>
                    </Tooltip>
                    <Tooltip title="Usuń użytkownika">
                        <CloseOutlined onClick={() => {
                            const payload = {
                                title: 'Usuń użytkownika',
                                open: true,
                                setOpen: setUserDeleteModal,
                                yesAction: () => {
                                    deleteUser(record.email)
                                    setUserDeleteModal(false);
                                },
                                noAction: () => setUserDeleteModal(false),
                            } as ConfirmationModalProps;

                            if (record.isManager) {
                                payload.infoboxTitle = "Wybrany użytkownik jest manadżerem i zostanie usunięty"
                                payload.children = <>
                                    <p className="text-slate-600 mb-1">Jeżeli usuniesz tego użytkownika, wszyscy inni
                                        użytkownicy, którzy bezpośrednio podlegają pod tego manadżera stracą
                                        bezpośredniego przełożonego.</p>
                                    <p className="text-slate-600">Usunięty użytkownik nie może zostać przywrócony, czy
                                        jesteś pewien, że chcesz usunąć {record.name} ({record.email})?</p>
                                </>
                            } else {
                                payload.infoboxTitle = "Wybrany użytkownik zostanie usunięty"
                                payload.children = <>
                                    <p className="text-slate-600">Usunięty użytkownik nie może zostać przywrócony, czy
                                        jesteś pewien, że chcesz usunąć {record.name} ({record.email})?</p>
                                </>
                            }
                            setModelPayload(payload);
                            setUserDeleteModal(true);
                        }}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const searchUsers = (value: any, fromString = false) => {
        const searchTerm = fromString ? value.toLowerCase() : value.target.value.toLowerCase()

        setSearch(searchTerm);

        if (searchTerm === '')
            setFilteredUserList(userList);
        else
            setFilteredUserList(userList.filter(x => searchFilter(x, searchTerm)));
    }

    const openDrawer = (record: User) => {
        setDrawerOpen(true);
        setSelectedUser(record);
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
        setSelectedUser({} as User)
    }

    const refreshUsers = () => {
        UserService.getUsers().then(x => {
            setUserList(x);
            setFilteredUserList(x.filter(y => searchFilter(y, search)));
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.USERS);

        UserService.getUsers().then(x => {
            setUserList(x);
            setFilteredUserList(x);
        });

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/users']}>Zarządzanie użytkownikami</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                {contextHolder}
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton outline onClick={() => auth.navigate('/admin/users/new')}
                                       className="lg:hidden min-w-[150px]">Dodaj</RetoricButton>
                        <RetoricButton outline onClick={() => setUserImportModalOpen(true)}
                                       className="min-w-[150px]">Importuj</RetoricButton>
                    </Space>
                    <div className="flex-1 mb-4">
                        <Input.Search placeholder="Wyszukaj użytkownika" allowClear size={'large'}
                                      onChange={(value: any) => searchUsers(value)}
                                      className="search bg-offwhite"/>
                    </div>
                    <div className={'users__new'}></div>
                </div>
                <Table columns={columns} dataSource={filteredUserList} rowKey={'id'} scroll={{x: 768}}
                       pagination={{position: ['bottomCenter']}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
                <UserDrawer closeDrawer={closeDrawer} drawerOpen={drawerOpen} selectedUser={selectedUser}
                            users={userList}/>
                <UserImportModal open={userImportModalOpen} setOpen={setUserImportModalOpen}
                                 refreshUsers={refreshUsers}/>
                <ConfirmationModal {...modelPayload} open={userDeleteModal} setOpen={setUserDeleteModal}/>
            </RetoricCard>
        </div>
    );
}

export default UsersList;
