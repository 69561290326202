import React, {useEffect} from 'react';
import './LoginTemporaryPassword.css';
import {Form, Input, notification} from "antd";
import {useAuth} from "../../extensions/Auth";
import {LockOutlined} from "@ant-design/icons";
import Logo from '../../assets/logotype.svg'
import LogoDark from '../../assets/logotype-dark.svg'
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {LoginService} from "../../services/LoginService";
import {ErrorMessages} from "../../extensions/ErrorMessages";
import {SystemService} from "../../services/SystemService";

function LoginTemporaryPassword({logo, logoDark}: { logo?: string, logoDark?: string }) {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = React.useState(false);
    const [loginError, setLoginError] = React.useState('');
    const [minimumPasswordLength, setMinimumPasswordLength] = React.useState(0);

    useEffect(() => {
        SystemService.getAccountSettings().then((x) => {
            setMinimumPasswordLength(x.minimumPasswordLength);
        });
    }, []);

    const onFinish = (values: any) => {
        if (values.password !== values.passwordRepeat) {
            setLoginError('Podane hasła nie są takie same!')
            return;
        }

        LoginService.updatePassword(values.password).then((x) => {
            const user = {
                email: auth.user?.email,
                password: values.password
            }

            auth.handleLogin(user).then((x: boolean) => {
                if (x)
                    setLoginError('');
                else
                    setLoginError('Wystąpił błąd podczas logowania!');

                setLoading(false);
            }).catch((e: any) => {
                setLoginError('Wystąpił błąd podczas logowania!');
                setLoading(false);
            });
        }).catch((e) => {
            let message = 'Nowe hasło musi być inne od poprzedniego!';

            if (e.message === ErrorMessages.userNotFound)
                message = 'Wystąpił błąd wewnętrzny!';

            if (e.message === ErrorMessages.nameTooShort)
                message = `Hasło musi mieć co najmniej ${minimumPasswordLength} znaków!`;

            api.error({
                message: `Błąd`,
                description: message,
                placement: 'top'
            });
        });
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-[100vh] bg-[#eeeeee]">
            {contextHolder}
            <div className="container flex lg:flex-row flex-col-reverse rounded-2xl">
                <div className="flex-1 self-stretch bg-white lg:rounded-l-2xl rounded-2xl">
                    <div className="flex-1 self-stretch lg:p-16 p-4">
                        <img src={logoDark || LogoDark} alt="Logo" className="lg:hidden w-[50%] mx-auto my-4"/>
                        <h1 className="lg:text-3xl text-2xl text-slate-600 text-center lg:text-left tracking-wide mb-4">Zmień
                            hasło</h1>
                        <p className="text-xl text-slate-600 text-center lg:text-left tracking-wide">Wygląda na to, że
                            zalogowałeś się korzystając z tymczasowego hasła lub Twoje hasło wygasło. Hasło tymczasowe
                            musi zostać zmienione przy pierwszym logowaniu.</p>
                        <Form
                            name="basic"
                            layout={'vertical'}
                            className="flex flex-col mt-8"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Nowe hasło"
                                name="password"
                                rules={[{required: true, message: 'Hasło jest wymagane!'}]}
                            >
                                <Input.Password prefix={<LockOutlined/>} size={'large'} placeholder={'Hasło...'}
                                                className="rounded-none"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Powtórz nowe hasło:"
                                name="passwordRepeat"
                                rules={[{required: true, message: 'Hasło jest wymagane!'}]}
                            >
                                <Input.Password prefix={<LockOutlined/>} size={'large'} placeholder={'Hasło...'}
                                                className="rounded-none"
                                />
                            </Form.Item>

                            <Form.Item
                                validateStatus={loginError ? 'error' : 'success'}
                                className="flex lg:flex-row flex-col gap-4 lg:justify-between w-full"
                                help={loginError ? loginError : ''}
                            >
                                <div className="flex lg:flex-row flex-col gap-4 lg:justify-between w-full">
                                    <RetoricButton size='lg' htmlType="submit" loading={loading}
                                                   className="min-w-[200px]">
                                        Zmień hasło
                                    </RetoricButton>
                                    <RetoricButton size='lg' type={'quaternary'} onClick={auth.handleLogout}
                                                   className="min-w-[200px]">
                                        Wyloguj
                                    </RetoricButton>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div
                    className="flex-1 self-stretch justify-center items-center bg-gradient-to-br from-brandColor-950 to-brandColor4-800 rounded-r-2xl hidden lg:flex">
                    <img src={logo || Logo} alt="Logo" className="w-[50%]"/>
                </div>
            </div>
        </div>
    );
}

export default LoginTemporaryPassword;
