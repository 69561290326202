import React, {useEffect} from 'react';
import {Descriptions, Drawer, Space} from "antd";
import {useAuth} from "../../../extensions/Auth";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {
    Infopage,
    InfopageCategory,
    InfopageChangelog,
    InfopagePhoneExtensions,
    InfopageTraining,
    InfopageType
} from "../../../types/InfopageModels";
import {InfopageService} from "../../../services/InfopageService";
import {InfopageTypeMapper} from "../InfopageList/InfopageList";
import {FormatDateSimple, IsFutureDate, IsPastDate} from "../../../extensions/DateFormatter";

export interface InfopageDrawerProps {
    selectedInfopage: Infopage;
    drawerOpen: boolean;
    closeDrawer: () => void;
    categories: InfopageCategory[];
}

function InfopageDrawer({selectedInfopage, drawerOpen, closeDrawer, categories}: InfopageDrawerProps) {
    const [activeInfopage, setActiveInfopage] = React.useState({} as Infopage);
    const auth = useAuth();

    const getCategoryName = (id?: string) => {
        const category = categories.find(x => x.id === id);
        return category?.title ?? 'Brak kategorii';
    }

    useEffect(() => {
        if (selectedInfopage.id !== undefined) {
            InfopageService.getInfopageById(selectedInfopage.id).then((x) => {
                if (x !== null) setActiveInfopage(x);
            });
        }
    }, [selectedInfopage]);

    return (
        <Drawer title="Informacje o użytkowniku" placement="right" onClose={closeDrawer} open={drawerOpen}
                extra={
                    <Space>
                        <RetoricButton onClick={() => auth.navigate(`/admin/info/edit/${selectedInfopage.id}`)}>
                            Edytuj
                        </RetoricButton>
                    </Space>
                }>
            <Descriptions title={activeInfopage.title} bordered>
                <Descriptions.Item label="Tytuł" span={3}>{activeInfopage.title}</Descriptions.Item>
                <Descriptions.Item label="Widoczoność strony"
                                   span={3}>{activeInfopage.isHidden ? 'Ukryta' : 'Widoczna'}</Descriptions.Item>
                <Descriptions.Item label="Kategoria"
                                   span={3}>{getCategoryName(activeInfopage.categoryId)}</Descriptions.Item>
                <Descriptions.Item label="Typ" span={3}>{InfopageTypeMapper(activeInfopage.type)}</Descriptions.Item>
                <Descriptions.Item label="Utworzono"
                                   span={3}>{FormatDateSimple(activeInfopage.createdAt)}</Descriptions.Item>
                <Descriptions.Item label="Edytowano"
                                   span={3}>{FormatDateSimple(activeInfopage.updatedAt)}</Descriptions.Item>
                {activeInfopage.type === InfopageType.PhoneExtensions && (
                    <>
                        {(activeInfopage as InfopagePhoneExtensions).entries.length > 0 ?
                            <Descriptions.Item label="Kategorie"
                                               span={3}>{(activeInfopage as InfopagePhoneExtensions).entries.map(x =>
                                <span className="block italic"
                                      key={x.id}>{x.title} (liczba numerów: {x.extensions.length})</span>
                            )}</Descriptions.Item>
                            :
                            <Descriptions.Item label="Kategorie" span={3}>Brak numerów</Descriptions.Item>
                        }
                    </>
                )}
                {activeInfopage.type === InfopageType.Training && (
                    <>
                        <Descriptions.Item label="Aktywne kursy" span={3}>
                            {(activeInfopage as InfopageTraining).entries.filter(x => IsFutureDate(x.date)).length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Historyczne kursy" span={3}>
                            {(activeInfopage as InfopageTraining).entries.filter(x => IsPastDate(x.date)).length}
                        </Descriptions.Item>
                    </>
                )}
                {activeInfopage.type === InfopageType.Changelog && (
                    <>
                        <Descriptions.Item label="Liczba wierszy" span={3}>
                            {(activeInfopage as InfopageChangelog).entries.length}
                        </Descriptions.Item>
                    </>
                )}
            </Descriptions>
        </Drawer>
    );
}

export default InfopageDrawer;
