import {ErrorObject, ErrorStatus, SuccessObject, User, UserApp, UserRequest} from "../types/AuthState";
import jwt_decode from "jwt-decode";

export const BACKEND_BASE = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:5000'
export const MOCK_ENABLED = process.env.REACT_APP_MOCK_ENABLED === 'true';
export const DEFAULT_CITY = 'Będzin';

export const GetToken = () => {
    const auth = localStorage.getItem('auth');

    if (auth)
        return JSON.parse(auth).token;

    return null;
}

export const Logout = () => {
    localStorage.removeItem('auth');
    window.location.reload();
}

export const LoginService = {
    login: async (email: string, password: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true'
                },
                body: JSON.stringify({email, password})
            });

            if (response.status !== 200) {
                return false;
            }

            const token = await response.text();
            const decoded: any = jwt_decode(token);
            const payload: User = JSON.parse(decoded['payload']);
            payload.token = token;

            return payload;
        } catch (e) {
            return false;
        }
    },
    getUserApps: async () => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/userApps`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return false;
            }

            return await response.json();
        } catch (e) {
            return false;
        }
    },
    addUserApp: async (app: UserApp): Promise<boolean> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/userApp`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(app)
            });

            if (response.status === 401) {
                Logout();
                return false;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    editUserApp: async (app: UserApp) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/userApp/${app.id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(app)
            });

            if (response.status === 401) {
                Logout();
                return false;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    deleteUserApp: async (id: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/userApp/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return false;
            }

            return response.status === 200;
        } catch (e) {
            return false;
        }
    },
    editUser: async (user: UserRequest): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/profile`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(user)
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    getUser: async (): Promise<User | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/profile`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                throw new Error('unauthorized');
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return await response.json();
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
    updatePassword: async (password: string): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Auth/profile/password`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({password})
            });

            if (response.status === 401) {
                return Promise.reject(new Error('unauthorized'));
            }

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
}
