import {BACKEND_BASE, GetToken} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {
    IntranetAuth,
    Report,
    ReportMessageRequest,
    ReportOperatorRequest, ReportResponse,
    Reports,
    ReportState
} from "../types/Signalist";
import {StaticAsset} from "../types/StaticAsset";

const SignalistService = {
    getToken: async (): Promise<IntranetAuth> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/auth/intranet`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getReports: async (): Promise<Report[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/report`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getReport: async (reportId: string): Promise<Report> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/report/${reportId}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addReport: async (request: ReportOperatorRequest): Promise<ReportResponse> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/report`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updateStatus: async (reportId: string, newStatus: ReportState): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/report/${reportId}/${newStatus}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getReportCategories: async (): Promise<Reports[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/categories`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addCategory: async (title: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/categories`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({title})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updateCategory: async (id: string, title: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/categories/${id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({title})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    deleteCategory: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/categories/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getTypes: async (): Promise<Reports[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/types`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addType: async (title: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/types`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({title})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updateType: async (id: string, title: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/types/${id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({title})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    deleteType: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/types/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updatePassword: async (password: string, passwordConfirmation: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/password`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({password, passwordConfirmation})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addAttachment: async (file: File): Promise<StaticAsset> => {
        try {
            const data = new FormData();
            data.append('file', file);

            const response = await fetch(`${BACKEND_BASE}/Signalist/attachment`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: data
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r));
            }

            const responseObject: StaticAsset = await response.json();
            responseObject.url = `${BACKEND_BASE}/Static/image/${responseObject.name}`;

            return responseObject;
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    openAttachment: (name: string): void => {
        fetch(`${BACKEND_BASE}/Signalist/attachment/${name}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': BACKEND_BASE,
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer ' + GetToken()
            }
        })
            .then(response => response.blob())
            .then(result => {
                const file = window.URL.createObjectURL(result);
                window.open(file, '_blank');
            })
            .catch(error => console.log('error', error));
    },
    addReportMessage: async (id: string, request: ReportMessageRequest): Promise<Report> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/report/${id}/message`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    openFullReport: (from?: Date, to?: Date): void => {
        let url = `${BACKEND_BASE}/Signalist/admin/fullReport`;

        if(from && to) {
            url += `?from=${from.toISOString()}&to=${to.toISOString()}`;
        } else if(from) {
            url += `?from=${from.toISOString()}`;
        } else if(to) {
            url += `?to=${to.toISOString()}`;
        }

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': BACKEND_BASE,
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer ' + GetToken()
            }
        })
            .then(response => response.blob())
            .then(result => {
                const file = window.URL.createObjectURL(result);
                window.open(file, '_blank');
            })
            .catch(error => console.log('error', error));
    },
    getReportId: async (): Promise<string> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/id`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.text();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getPassword: async (): Promise<string> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/password`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.text();
        } catch (e) {
            throw new Error('unauthorized');
        }
    }
}

export default SignalistService;
