import React from 'react';
import './Mail.css';
import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";

function Mail() {
    return (
        <div className="content">
            <RetoricBreadcrumbs>Poczta</RetoricBreadcrumbs>
        </div>
    );
}

export default Mail;
