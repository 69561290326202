import React, {useEffect, useState} from 'react';
import './NewsEdit.css';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from "draft-js";
import {Input, notification} from "antd";
import {convertFromHTML, convertToHTML} from 'draft-convert';
import {NewsService} from "../../../services/NewsService";
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {AdminScopes} from "../../../types/Scopes";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../extensions/Auth";
import {NewsCardProps} from "../../../components/NewsCard/NewsCard";
import {ErrorMessages} from "../../../extensions/ErrorMessages";
import {uploadCallback} from "../NewsAdd/NewsAdd";
import {Attachment} from "../../../types/StaticAsset";
import {BACKEND_BASE} from "../../../services/LoginService";
import AttachmentsDragger from "../../../components/AttachmentsDragger/AttachmentsDragger";

export const HtmlConverter = convertFromHTML({
    htmlToBlock: (nodeName, node) => {
        if (nodeName === 'img') {
            const data: any = {
                src: node.src,
                alt: node.alt
            };

            if (node.height)
                data.height = node.height;

            if (node.width)
                data.width = node.width;

            if (node.align)
                data.alignment = node.align;

            return {
                type: 'atomic',
                mutability: 'mutable',
                data
            };
        }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'img') {
            const data: any = {
                src: node.src,
                alt: node.alt
            };

            if (node.height)
                data.height = node.height;

            if (node.width)
                data.width = node.width;

            if (node.align)
                data.alignment = node.align;

            return createEntity(
                'IMAGE',
                'MUTABLE',
                data
            );
        }
    }
})

export const FromHtmlConfig = {
    entityToHTML: (entity: any, originalText: any) => {
        if (entity.type === 'IMAGE') {
            if (entity.data.alignment === 'none' || !entity.data.alignment)
                return `<center><img src="${entity.data.src}" alt="${entity.data.alt ?? 'Image'}" width="${entity.data.width}" height="${entity.data.height}"></center>`;

            return `<img src="${entity.data.src}" alt="${entity.data.alt ?? 'Image'}" width="${entity.data.width}" height="${entity.data.height}" align="${entity.data.alignment}">`;
        }

        return originalText;
    }
};

function NewsEdit() {
    const auth = useAuth();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState({} as NewsCardProps);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);

    let {id} = useParams();

    const locations = [...Locations['admin/news'], {
        title: `Edycja: ${data.title}`,
        path: `/admin/news/edit/${id}`
    }]

    useEffect(() => {
        auth.verifyAuth(AdminScopes.NEWS);

        if (!id) {
            auth.navigate('/news/manage')
            return
        }

        NewsService.getNewsEntry(id!).then((r) => {
            setData(r);
            setTitle(r.title || '');
            setDescription(r.description || '');
            const result = HtmlConverter(r.description || '');
            setEditorState(EditorState.createWithContent(result));
            setAttachments(r.attachments?.map(x => {
                return {
                    uploadId: x.id,
                    asset: x
                }
            }) ?? []);
            setFileList(r.attachments?.map(x => {
                return {
                    uid: x.id,
                    name: x.friendlyName,
                    status: 'done',
                    url: `${BACKEND_BASE}/static/${x.name}`
                }
            }) ?? []);
        });
    }, [id, auth]);

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(FromHtmlConfig)(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description) {
            api.error({
                message: `Uzupełnij tytuł i treść`,
                description: 'Tytuł i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const payload = {
            ...data,
            title,
            description,
            attachments: attachments.map(x => x.asset)
        }

        NewsService.editNewsEntry(payload).then(() => {
            api.info({
                message: `Zaktualizowano aktualność`,
                description: 'Aktualność została zaktualizowana!',
                placement: 'top'
            });
            setTitle('');
            setDescription('');
            setEditorState(EditorState.createEmpty());
            setAttachments([]);
            setFileList([]);
        }).catch((e) => {
            if (e.message === ErrorMessages.notFound)
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd, nie znaleziono aktualności!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    return (
        <div className="content">
            {contextHolder}
            <RetoricBreadcrumbs locations={locations}>Edytuj aktualność</RetoricBreadcrumbs>
            <Infobox title="Edytowanie aktualności"
                     rows={[
                         'W tej sekcji możesz edytować aktualność dla swoich współpracowników.'
                     ]}
                     className="self-stretch"
            />
            <Input size="large" placeholder="Tytuł" value={title} defaultValue={data.title}
                   onChange={updateTitle}
                   className="bg-offwhite-important rounded-none placeholder:text-gray-500"/>
            <Editor
                editorState={editorState}
                locale={'pl'}
                toolbar={{
                    image: {
                        uploadCallback: uploadCallback,
                        uploadEnabled: true,
                        previewImage: true,
                        alt: {present: true, mandatory: false},
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp',
                    }
                }}
                wrapperClassName="flex-1 self-stretch border border-gray-200 bg-offwhite"
                editorClassName="px-4 bg-offwhite min-h-[20vh]"
                onEditorStateChange={updateEditorState}
            />
            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments} fileList={fileList}
                                setFileList={setFileList}/>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Zapisz
                aktualność</RetoricButton>
        </div>
    );
}

export default NewsEdit;
